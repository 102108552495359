@import 'src/styles/imports';
@import '../important-dates.module.scss';

.dateItem {
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: sticky;
  top: 3.4rem;

  &.firstItem {
    padding-top: 3.4rem;
  }

  &.period {
    display: flex;
    gap: 0.4rem;
  }

  .date {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    text-align: right;

    .dayOfWeek {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }

    .dateText {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: bold;
    }

    .endMonthText {
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: bold;
    }
  }
}
