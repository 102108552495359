@import 'src/styles/imports';

.visibilityWrapper {
  position: absolute;
  top: 8px;
  opacity: 0;
  z-index: $layer-search-modal;
  transition: all 0.3s ease-in-out;

  @include media(S) {
    position: relative;
    max-height: 0;
  }
}

.calendarContainer {
  display: grid;
  margin-bottom: 4rem;
  grid-template-columns: repeat(7, 1fr);
  background-color: var(--card-bg-color);
  padding: 8px 0;
  border-radius: 12px;
  row-gap: 4px;
  box-shadow: $shadow-lg;

  @include media(S) {
    background-color: transparent;
    margin-bottom: 1rem;

    .headerItem {
      display: none;
    }
  }

  // month heading
  .month {
    grid-column: 2/7;
    font-weight: 800;
    line-height: 28px;

    > div {
      width: revert;
    }
  }
}

.unitDots {
  color: inherit;
  position: absolute;
  bottom: 1px;
  display: flex;
  flex-direction: row;
  left: 50%;
  transform: translateX(-50%);
  gap: 2px;

  .dot {
    height: 3px;
    width: 3px;
    background: currentColor;
    border-radius: 4px;
  }

  .plus {
    height: 4px;
    width: 4px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      background: currentColor;
    }

    &::before {
      top: -15%;
      left: 15%;
      width: 25%;
      margin-left: 25%;
      height: 100%;
      border-radius: 1px;
    }

    &::after {
      left: 0;
      top: 45%;
      height: 20%;
      margin-top: -15%;
      width: 100%;
      border-radius: 1px;
    }
  }
}

.enter {
  top: 8px;
  opacity: 1;

  @include media(S) {
    top: 0;
    max-height: var(--mini-cal-height);
  }
}

.exit {
  top: -16px;
  opacity: 0;

  @include media(S) {
    top: 0;
    max-height: 0;
  }
}
