.themeSelector {
  * {
    pointer-events: none;
  }
  .dynamic {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    top: 25px;
    left: 39%;

    svg {
      filter: drop-shadow(0px 0px 1px rgba(#000000, 0.5));
      z-index: 3;
      stroke: #531e37;
      stroke-width: 0.2rem;
      width: 100%;
      height: 100%;
      fill: #f7d803;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &.selected {
    border-radius: 1.2rem;
    box-shadow: 0 0 0 2px var(--card-cta-bg-color);
  }
  &:focus-visible {
    border-radius: 1.2rem;
    outline-offset: 4px;
  }
  .wrapper {
    width: 100%;
    height: 4rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 2px solid var(--card-background-color);

    .sampleUI {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 10%;

      .text {
        height: 10%;
        width: 50%;
        border-radius: 0.4rem 0 0 0.4rem;
        margin-right: 0.4rem;
      }
      .card {
        height: 40%;
        width: 50%;
        border-radius: 0.4rem 0 0.6rem 0;
        margin: 0 0.4rem 0.4rem 0;
      }
    }
  }
}
