@import 'src/styles/imports';

.placeholder {
  height: 100%;
  width: 100%;
  padding: $nav-container-height 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--canvas-text-color);
  gap: 4rem;
  overflow-y: scroll;
  @include customScrollbar;

  > img {
    width: 24rem;

    @media screen and (max-width: $mobile-layout-width) {
      width: 18rem;
    }
  }

  .content {
    text-align: center;
    height: fit-content;

    // offset nav and page header to make this visually "center aligned"
    // margin-bottom: calc($page-container-top-padding - 40px);
    @include gappedColumn(4rem);

    @media screen and (max-width: $mobile-layout-width) {
      padding: 0;
    }

    .body {
      @include gappedColumn(2rem);
      align-items: center;
    }
  }
}
