@import 'src/styles/imports';

.filterAccordion {
  .text {
    display: flex;
    text-align: start;
  }

  :global(.accordionItems) {
    padding: 0 28px 0 20px;
    margin: 0;
  }

  :global(.childrenContent) {
    padding: 16px 0 8px;
    @include gappedColumn(8px);
  }

  :global(.subHeader) {
    color: var(--card-text-color);

    svg {
      stroke: var(--card-text-color);
    }
  }
}
