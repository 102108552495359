@import 'src/styles/imports';

.shortCuts {
  width: 100%;
  height: 100%;

  .shortcutsList {
    padding: 2rem;
    @include gappedColumn(1rem);

    .shortcut {
      height: fit-content;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      color: var(--card-text-primary-color);
      border: 1px solid var(--card-border-color);
      padding: 1rem 1.6rem;
      border-radius: 8px;
      overflow: hidden;

      &:hover {
        background-color: var(--card-active-bg-color);
      }

      .icon {
        height: 3.2rem;
        width: 3.2rem;
        font-size: 3.2rem;
        line-height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content {
        overflow: hidden;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .shortcutName {
          font-weight: 500;
        }

        .shortcutUrl {
          font-size: 1.4rem;
          color: var(--card-text-secondary-color);
        }
      }
    }
  }
  .emptyShortcuts {
    padding: 2rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include gappedColumn(1rem);

    .graphic {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include gappedColumn(1rem);

      .img {
        width: 40%;
        max-width: 140px;
      }
    }
  }

  .editShortcuts {
    width: 100%;
    padding: 0.8rem 0;
    display: flex;
    justify-content: center;
  }
}
