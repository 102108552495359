@use 'sass:math';
@import 'src/styles/imports';
@import 'src/components/pages/upcoming/components/TimeIndicator/TimeIndicator.module.scss';

$cal-col: var(--cal-number-of-column);

$cal-row-gap: 0.2rem;
$cal-col-gap: 0.8rem;
$label-col-width: 4.8rem;

$cal-col-width: calc((100% - #{$cal-col-gap * 6}) / 7);
$cal-grid: auto-flow / repeat(7, $cal-col-width); // calendar grid layout for list view
$exp-col-width: calc(((100% - #{$label-col-width}) - #{$cal-col-gap * 7}) / 7);
$exp-grid: auto-flow / $label-col-width repeat(7, $exp-col-width); // calendar grid layout for expanded(full) view

$cal-header-border-color: rgba(0, 0, 0, var(--opacity-canvas-dimming));
$cal-col-bg-highlight-color: rgba(0, 0, 0, var(--opacity-canvas-dimming));
$cal-row-base-height: 64px; // align with constant.js

$header-height: $cal-row-base-height * 4;
$anchor-scroll-margin: $cal-row-base-height * 4;
$item-scroll-margin: $cal-row-base-height;

// z-index values
$layer-cal-column-highlight-days: -1;
$layer-cal-column-highlight: 0;
$layer-cal-time-grid: 1;
$layer-cal-items: 2;

$custom-scrollbar-offset: 8px;
$custom-scrollbar-width: var(--custom-scrollbar-width);
$full-width-minus-scrollbar-offset: calc(100% - #{$custom-scrollbar-offset});
$full-width-minus-scrollbar-width: calc(100% - #{$custom-scrollbar-width});

// shared calendar columns setup
@mixin calendarColumnsSetup($grid) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat($cal-col, minmax(0, 1fr));
  column-gap: $cal-col-gap;
  grid-auto-flow: column;

  @if ($grid == $exp-grid) {
    grid-template-columns: 48px repeat($cal-col, minmax(0, 1fr));
  }
}

// shared calendar day highlight column base styles
@mixin calendarColumnHighlightBase() {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  background: $cal-col-bg-highlight-color;
  z-index: $layer-cal-column-highlight;
}

.calendarBorderContainer {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: calc(100% + #{$custom-scrollbar-offset});
  min-height: 50vh;

  @media screen and (max-width: $mobile-layout-width) {
    &[data-expanded='false'] {
      border: 1px solid rgba(0, 0, 0, var(--opacity-canvas-dimming));
      border-radius: 1.7rem;
    }
  }
}

.body {
  // Extra padding needed to prevent the time indicator
  // from being cut off.
  padding-left: $half-dot-size;
  display: flex;
  flex-direction: column;
  flex: 1;
  scrollbar-gutter: stable;
  @include customScrollbar;
}

.keyline {
  background-color: $cal-header-border-color;
  content: '';
  margin-top: -2px;
  height: 2px;
  width: $full-width-minus-scrollbar-offset;
}

.daysHeader {
  $container-v-gap: 1.2rem;
  $bottom-border-size: 2px;
  position: relative;
  padding: $container-v-gap 0 0.4rem 0;
  // Extra padding needed to prevent the time indicator
  // from being cut off.
  padding-left: $half-dot-size;
  // Extra padding to align with schedule grid (which has a scrollbar)
  padding-right: $custom-scrollbar-offset;
  z-index: $layer-cal-sticky-headers - 1;
  transition: top 0.3s;

  &[data-expanded='true'] {
    @include calendarColumnsSetup($exp-grid);
    // Browser-specific hack to account for custom scroll bar
    width: $full-width-minus-scrollbar-width;
  }

  &[data-expanded='false'] {
    @include calendarColumnsSetup($cal-grid);
    // Browser-specific hack to account for custom scroll bar
    width: $full-width-minus-scrollbar-width;
  }

  @-moz-document url-prefix() {
    width: 100% !important;
  }

  .days {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: var(--canvas-text-color);
    z-index: $layer-cal-items;

    &[data-highlight] {
      color: var(--card-text-color);
    }

    &[data-highlight]:after {
      @include calendarColumnHighlightBase();
      top: -1.2rem;
      bottom: -0.4rem;
      height: auto;
      border-radius: 1.6rem 1.6rem 0 0;
      z-index: $layer-cal-column-highlight-days;

      @media screen and (max-width: $small-mobile-layout-width) {
        border-radius: 1.2rem 1.2rem 0 0;
      }
    }

    &[data-highlight]:before {
      transform: translateX(-50%);
      @include calendarColumnHighlightBase();
      left: 50%;
      background-color: var(--card-bg-color);
      border-radius: 8px;
      width: 4.6rem;
      color: var(--card-text-color);
      height: 100%;

      @media screen and (max-width: $mobile-layout-width) {
        width: 3.2rem;
      }

      @media screen and (max-width: $small-mobile-layout-width) {
        width: 2.8rem;
      }
    }

    & span {
      font-size: 1.2rem;
    }

    .date {
      font-size: 2rem;

      font-weight: 800;
      margin-top: -4px;
    }
  }

  .timezone {
    grid-column-start: 1;
    $size: 1.6rem;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    align-self: flex-end;
    color: var(--canvas-text-color);
    opacity: 0.4;
    width: 100%;
  }
}

.wrapper {
  position: relative;
  flex: 1;

  [data-expanded='false'] > div {
    min-height: 50vh;
  }
}

.keyDates {
  // Extra padding needed to prevent the time indicator
  // from being cut off.
  padding-left: $half-dot-size;

  // Extra padding to align with schedule grid (which has a scrollbar)
  padding-right: $custom-scrollbar-offset;

  &[data-expanded='true'] {
    @include calendarColumnsSetup($exp-grid);
    // Browser-specific hack to account for custom scroll bar
    width: $full-width-minus-scrollbar-width;
  }

  &[data-expanded='false'] {
    @include calendarColumnsSetup($cal-grid);
    // Browser-specific hack to account for custom scroll bar
    width: $full-width-minus-scrollbar-width;
  }

  @-moz-document url-prefix() {
    width: 100% !important;
  }

  .keyDatesColumn {
    position: relative;

    // set height b/c key dates children are positioned absolutely
    // when there are multiple key dates on one day
    height: 40px;

    &:focus-visible {
      outline: 2px solid var(--canvas-input-focused-border-color);
      border-radius: 14px;
    }

    &[data-highlight]:after {
      @include calendarColumnHighlightBase();
      height: 100%;
      top: 0;
    }
  }

  .keyDateItemWrapper {
    margin: 2px 2px $cal-row-gap 2px;
    padding: 0;
    z-index: $layer-cal-items;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    border-radius: 14px;

    @media screen and (max-width: $mobile-layout-width) {
      border-radius: 10px;
    }
  }

  .keyDateItem {
    display: block;
    color: inherit;
    background: none;
    border: none;
    appearance: none;
    width: 100%;
    height: 32px;
    padding: 4px 8px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--card-bg-color);
    border-radius: 12px;
    // allows one line
    white-space: nowrap;

    @media screen and (max-width: $mobile-layout-width) {
      border-radius: 8px;
    }
  }

  .isMultiple {
    .keyDateItemWrapper {
      position: absolute;
    }
  }
}

.activeDayGrid {
  position: absolute;
  padding-right: 0.8rem;
  display: grid;
  grid-template-columns: repeat(var(--cal-number-of-column), minmax(0, 1fr));
  column-gap: 0.8rem;
  grid-auto-flow: column;
  width: 100%;
  height: 100%;

  [data-expanded='true'] & {
    grid-template-columns: 48px repeat(
        var(--cal-number-of-column),
        minmax(0, 1fr)
      );
  }

  .activeDay {
    background: rgba(0, 0, 0, var(--opacity-canvas-dimming));
    border-radius: 0 0 1.6rem 1.6rem;

    @media screen and (max-width: $small-mobile-layout-width) {
      border-radius: 0 0 1.2rem 1.2rem;
    }
  }
}

.timeGridWrapper {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  width: 100%;
  // Extra padding needed to prevent the time indicator
  // from being cut off.
  padding-top: $dot-size;

  .row {
    display: flex;
    top: 0; // updated by JS with dynamic top offset
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    height: $cal-row-base-height;
    z-index: $layer-cal-time-grid;

    // border to not overlap with custom scrollbar
    &::after {
      background-color: $cal-header-border-color;
      bottom: 0;
      content: '';
      height: 2px;
      left: $label-col-width;
      position: absolute;
      width: calc(100% - $label-col-width);
    }

    .label {
      $size: 1.6rem;
      content: attr(data-label);
      white-space: nowrap;
      color: var(--canvas-text-color);
      align-self: flex-end;
      bottom: -$size * 0.5;
      width: $label-col-width;
      height: $size;
      text-align: left;
      font-size: $size;
      line-height: 1;
      opacity: 0.4;

      &.anchor {
        scroll-margin-top: $anchor-scroll-margin;
      }
    }
  }

  .warningWrapper {
    padding-block: 25px;
  }
}

.headerMain {
  display: flex;
}

.schedule {
  width: 100%;
  height: auto;
  // Extra padding needed to prevent the time indicator
  // from being cut off.
  padding-top: $dot-size;

  .scheduleColumn {
    display: flex;
    flex-direction: column;
    gap: calc(10px - $cal-row-gap);
    position: relative;

    &:focus-visible {
      outline: 2px solid var(--canvas-input-focused-border-color);
      outline-offset: -2px;
      border-radius: 14px;
    }
  }

  .scheduleItem {
    z-index: $layer-cal-items;
    justify-self: stretch;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    position: relative;
    scroll-margin-bottom: $item-scroll-margin;
    padding: 0;
    border: 0;
    background: transparent;

    > button {
      height: 100%;
    }
  }

  &[data-expanded='true'] {
    @include calendarColumnsSetup($exp-grid);

    .scheduleColumn {
      height: $cal-row-base-height * 24; // full time grid height
    }

    // Scrollbar offset for Firefox
    @-moz-document url-prefix() {
      width: 100%;
    }
  }

  &[data-expanded='false'] {
    @include calendarColumnsSetup($cal-grid);

    .timeGridWrapper {
      display: none;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 100px;
  }
}

.hiddenEventsIndicator {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: var(--card-border-radius);
  background-color: var(--canvas-cta-bg-color);
  z-index: $layer-cal-sticky-headers - 1;
  box-shadow: var(--card-drop-shadow);
  transition: opacity 0.3s, background-color 0.3s;

  &:hover {
    background-color: var(--canvas-cta-active-bg-color);
  }

  &[data-show='true'] {
    opacity: 1;
  }

  &[data-show='false'] {
    opacity: 0;
    cursor: default;
  }

  &[data-mobile='true'] {
    position: absolute;
  }

  .hiddenEventsButton {
    color: var(--canvas-cta-text-color);
    padding: 2px 12px;

    &:disabled {
      color: var(--card-text-secondary-color);
      cursor: default !important;
    }

    svg {
      stroke: var(--canvas-cta-text-color);
    }
  }
}
