@import '../../../styles/mixins';
@import '../../../styles/variables';

.wrapper {
  display: grid;
  position: static;
  gap: 80px;
  grid-template-columns: 1fr 1fr;
  @include customScrollbar;

  @media screen and (min-width: ($mobile-layout-width + 1px)) {
    height: 100vh;
  }

  @media screen and (max-width: $mobile-layout-width) {
    gap: 40px;
    grid-template-columns: 1fr;
  }
}

.Today {
  min-height: 100vh;
  @include customScrollbar;
}
