@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.dueTime {
  margin-bottom: 10px;
  font-size: 1.6rem;
}

.eventsList {
  position: static;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > div:last-child {
    margin-bottom: 6rem;
  }
}

.location {
  margin-top: 10px;
  font-size: 1.6rem;
  line-height: 1.4;
}

.classHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}

.classHeader {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 16px;
}

.status {
  line-height: normal;
  span {
    line-height: normal;
  }
}

.warningBox {
  margin-bottom: 2rem;
}

.oldTime {
  text-decoration: line-through;
}

.classHeaderText {
  @include gappedColumn(5px);

  abbr {
    text-decoration: none;
  }
}
