@import 'src/styles/imports';

.popupWrapper {
  height: auto;

  &.isHidden {
    height: 0;
    pointer-events: none;
    overflow: hidden;
  }
  hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--card-border-color);
  }
}

.popup {
  color: var(--card-text-color);
  background-color: var(--card-bg-color);
  padding: var(--card-padding);
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  height: 600px;
  width: 400px;
  text-align: left;
  @include gappedColumn(1rem);
  box-shadow: $shadow-xl;
  margin-bottom: 1rem;

  .tabs {
    max-width: 100%;
    min-height: 58px;
    overflow: auto;
    margin-bottom: 1rem;
  }

  .tabWrapper {
    overflow-y: auto;
  }

  .inputGroups {
    @include gappedColumn(2rem);

    .inputGroup {
      @include gappedColumn(1rem);
    }
  }
}

.testDate {
  font-size: 14px;
}

.formGroup {
  @include gappedColumn(2rem);
}

.radioGroup {
  .radioInputs {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;

    .radioInput {
      display: flex;
      align-items: center;

      label {
        font-weight: normal;
      }

      input {
        display: inline-block;
      }
    }
  }
}

.formFooter {
  display: flex;
  gap: 16px;
}

.inlineLabel {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

.inlineGroup {
  align-items: center;
  display: flex;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.fullWidthInput {
  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: bold;
}

.dateInputs {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.featureFlagsWrapper {
  @include gappedColumn(1rem);
}

.inputWrapper {
  width: 100%;
  @include gappedColumn(1rem);
}

.set {
  width: fit-content;
}

.indent {
  padding-left: 1.2rem;
  border-left: 0.5rem solid #ccc;
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
