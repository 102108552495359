@import '../../../styles/variables';

.stickyContainer {
  position: fixed;
  bottom: 6rem;
  right: 15px;
  text-align: right;
  z-index: $layer-sticky-container;
  & > * {
    margin-top: 10px;
    text-align: left;
  }
}
