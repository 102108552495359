@import 'src/styles/imports';
@import '~@monash/portal-react/dist/styles/standard.scss';

// font-family would be better applied in body, however
// there are overrides happening in our libraries.
// TODO: Move this to body
* {
  font-family: var(--global-font-family);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--canvas-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --custom-scrollbar-width: 14px;

  &.is-mobile-device {
    --custom-scrollbar-width: 0;
  }
}

html,
body,
#root,
.app {
  height: 100vh;
  height: 100dvh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .app-content-wrapper {
    display: flex;
    height: 100%;
  }
}
