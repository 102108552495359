@import '../../../../styles/imports';

.upcomingListing {
  overflow-x: hidden;
  padding-bottom: 40px;
  height: 100vh;
  position: absolute;
  padding-top: $page-container-top-padding;

  //  to allow for time indicator to appear offset
  padding-left: 4px;
  top: 0px;
  left: calc(50% + 30px);
  width: calc(50% - 80px);

  @include customScrollbar;

  .noEventsCard {
    width: 100%;
    font-weight: bold;
  }

  .secondaryListingHeading {
    color: var(--canvas-text-color);
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  :global([data-banner-shown='UPCOMING']) & {
    top: unset;
  }
}

.location {
  font-size: 1.4rem;
}
