@import 'src/styles/imports';

.collapsedMultiDayEvents {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.outerWrapper {
  padding-right: 0.8rem;
}

.innerWrapper {
  display: grid;
  grid-template-columns: repeat(var(--cal-number-of-column), minmax(0, 1fr));
  column-gap: 0.8rem;
  grid-auto-flow: column;
}

.button {
  background: none;
  border: 0;
  padding: 0;
}
