@import 'src/styles/imports';

.navContainer {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: $nav-container-height;
  z-index: $layer-nav;

  // Add padding to nav specifically for notched phones in landscape. Body content is currently already sufficiently padded due to PageContainer padding
  // Note: safe-area-inset-* vars only resolve to non-zero values on non-rectangular screens (e.g. notched phones), so regular desktop views are unaffected
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  // in mobile, header condenses/expands on user scroll
  @media screen and (max-width: $mobile-layout-width) {
    // transition top instead of translate bc translate causes flickering in Safari
    will-change: top;
    transition: top 0.3s ease;
    height: unset;
  }
}

.backgroundContainer {
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .background {
    height: 100vh;
    transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-property: background-image, background-color, width, transform;

    // remove header background transition on mobile
    @media screen and (max-width: $mobile-layout-width) {
      transition: none;
    }
  }
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 2rem;

  .left {
    cursor: pointer;

    svg {
      pointer-events: none;
      display: block;

      path {
        fill: var(--canvas-text-color);
      }
    }
  }

  .middle {
    width: 100%;
    min-width: 0;
    justify-content: center;
    display: flex;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 2rem;

    & > div {
      gap: 20px;
      display: flex;
      flex-direction: row;
      height: 40px;
    }

    .navButton {
      display: flex;
      align-items: center;
      justify-content: center;

      .searchContainer {
        .searchButtonMobile {
          cursor: pointer;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background-color: transparent;
          border-radius: 40px;
          transition: background-color 0.2s;

          & svg {
            stroke: var(--canvas-text-color);
          }

          &:focus-visible {
            border-radius: 40px;
            outline-offset: 0px;
          }

          &:hover {
            background-color: var(--canvas-active-bg-color);
          }
        }

        .searchButton {
          padding: 1rem 1.6rem;
          white-space: nowrap;
          color: var(--canvas-text-color);
          background-color: var(--canvas-active-bg-color);

          > span {
            max-width: 16ch;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.hideTopNav {
  top: -64px;
}
