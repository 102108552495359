.AccentContainer {
  $stripeLightColour: #fff;
  $stripeDarkColour: #333;
  $stripeSize: 5px;
  $bgGradientHeight: 11.7px; // magic number
  $bgGradientWidth: 20px; // use this for horizontal accent

  display: block;
  align-self: stretch;
  opacity: 0.13;

  // common strips background
  background-color: transparent;
  background-size: 100% $bgGradientHeight;
  background-position: left center;
  background-repeat: repeat;
  background-image: linear-gradient(
    30deg,
    $stripeDarkColour 0 $stripeSize,
    $stripeLightColour $stripeSize $stripeSize * 2,
    $stripeDarkColour $stripeSize * 2 $stripeSize * 3,
    $stripeLightColour $stripeSize * 3 $stripeSize * 4
  );

  // size variants
  border-radius: 4px;
  flex: 0 0 8px;
  width: 8px;
  min-height: 100%;

  &[data-size='sml'] {
    border-radius: 3px;
    flex-basis: 6px;
    width: 6px;
  }

  &[data-size='big'] {
    border-radius: 5px;
    flex-basis: 10px;
    width: 10px;
  }

  // state variants
  &[data-loading='true'] {
    animation-name: barbershop;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 0.5s;

    &[data-orientation='horizontal'] {
      animation-name: barbershopHorizontal;
    }
  }

  &[data-orientation='horizontal'] {
    height: 6px;
    width: 100%;
    background-size: $bgGradientWidth 100%;
  }

  @keyframes barbershop {
    from {
      background-position: 0 0;
    }

    to {
      background-position: 0 $bgGradientHeight;
    }
  }

  @keyframes barbershopHorizontal {
    from {
      background-position: 0 0;
    }

    to {
      background-position: $bgGradientWidth 0;
    }
  }
}
