@import 'src/styles/imports';

$bar-height: 8px;

.weekSelector {
  display: flex;
  padding-block: 35px 25px;
}

.barWrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  cursor: pointer;
  padding: 0 1px;
}

.bar {
  appearance: none;
  width: 100%;
  height: 28px;
  border-radius: 1px;
  border: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s background-color;

  // Background of the bar
  &::before {
    content: '';
    display: block;
    margin-top: 10px;
    height: $bar-height;
    width: 100%;
    background-color: var(--card-text-color);
    opacity: 0.2;
  }

  // Bar at bottom of block
  &::after {
    display: block;
    position: absolute;
    left: 0;
    bottom: 10px;
    content: '';
    width: 100%;
    height: 0px;
    background: var(--card-bg-color);
    transition: height 0.2s;
  }

  &.isInTeachingPeriod {
    &::before {
      background-color: var(--card-bg-color);
    }
  }

  &.isInTeachingPeriod {
    color: var(--card-bg-color);

    // Bar at bottom of block
    &::after {
      height: 1px;
    }
  }

  &.isSelected {
    &::after {
      height: $bar-height;
    }
  }

  &:hover {
    &::after {
      height: $bar-height;
    }
  }
}

.label {
  position: absolute;
  opacity: 0;
  color: var(--canvas-cta-bg-color);
  font-size: 1.6rem;
  font-weight: 500;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  top: -14px;
  transition: 0.3s opacity;

  &.overflowsLeft {
    max-width: 80px;
  }

  &.overflowsRight {
    max-width: 80px;
    display: flex;
    justify-content: flex-end;
  }

  &.show {
    opacity: 1;
  }
}

.barWrapper:hover .label {
  opacity: 1;
}

.currentWeek {
  font-size: 12px;
  position: absolute;
  padding: 0 6px;
  left: 50%;
  bottom: -22px;
  border-radius: 4px;
  background: var(--card-bg-color);
  color: var(--card-text-color);
  white-space: nowrap;
  transform: translateX(-50%);

  &::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--card-bg-color);
    border-width: 5px;
    margin-left: -5px;
  }
}
