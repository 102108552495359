@import '../../styles/imports';

.PageContainer {
  padding: $page-container-top-padding 40px 0;
  height: 100%;
  overflow-x: hidden;
  @include customScrollbar;

  @media screen and (max-width: $mobile-layout-width) {
    padding: 40px 16px 0;
    @include mobileCustomScrollbar;
  }

  &.upcoming {
    padding: 0 40px;

    @media screen and (max-width: $mobile-layout-width) {
      // 0 side padding to accomodate horizontal scrolling events
      padding: 40px 0 0;
    }
  }

  &.isFirst {
    @media screen and (max-width: $mobile-layout-width) {
      padding-top: $page-container-top-padding;
    }
  }
}
