@import '../../../../styles/mixins';

.gradesAccordion {
  h3 {
    color: var(--card-text-secondary-color);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
  }

  :global(.childrenContent) {
    padding: 0;
  }

  :global(.accordionItems) {
    @include gappedColumn(10px);
    margin: 0;
  }

  :global(.icon) {
    line-height: 0;
  }

  :global(.subHeader) {
    svg {
      stroke: var(--card-text-color);
    }
  }
}

.text {
  overflow: hidden;
  height: 2.4rem;
  transition: 0.3s height;
  transition-delay: 0.3s;

  &.isHidden {
    height: 0;
    transition-delay: 0s;
  }
}
