.colourPalette {
  // remove default list styling
  list-style-type: none;
  padding: 0;
  margin: 0;

  // custom styling
  color: var(--card-text-color);
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

  .colourSquare {
    // remove default button styling
    height: 40px;
    width: 40px;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    padding: 0;

    // custom styling of colour square button
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-color);
    border-radius: 1.2rem;
    width: 100%;
    aspect-ratio: 1;
    outline: 0px solid white;
    transition: outline 50ms linear;
    border: 1px solid transparent;
    outline-offset: -6px;

    &:hover,
    &:focus-visible {
      cursor: pointer;
      outline-width: 2px;
      outline-offset: -6px;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
