.offlineIndicator {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4rem;
  z-index: 100;
}

.transitionLayer {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 1;
  transition: opacity 0.3s ease;
  z-index: 9999;
}

.fadeOut {
  opacity: 0;
}
