$layer-unit-colour-submenu: 1;

.unitRow {
  // remove default button styling
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  // custom styling of the unit colour "row"
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: var(--card-active-bg-color);
  }
  &:focus-visible {
    outline: 2px solid var(--card-input-focused-border-color);
    outline-offset: -2px;
  }

  .unitCode {
    font-size: 16px;
  }

  .colourSquare {
    display: block;
    margin-left: auto;
    border-radius: 0.8rem;

    height: 24px;
    aspect-ratio: 1; // to maintain square shape relative to height

    &:hover {
      cursor: pointer;
    }
  }
}

.colourPalette {
  // remove default list styling
  list-style-type: none;
  padding: 0;
  margin: 0;

  // custom styling
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;

  .colourSquare {
    // remove default button styling
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    padding: 0;

    // custom styling of colour square button
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-color);
    border-radius: 1.5rem;
    width: 100%;
    aspect-ratio: 1;
    outline-color: transparent;
    outline-style: solid;
    outline-width: 3px;
    outline-offset: -6px;
    transition: outline-color 100ms ease-in;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      outline-color: white;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}

.container {
  position: fixed;
  z-index: $layer-unit-colour-submenu;
}

.paddedRow {
  padding-left: 20px;
  padding-right: 20px;
}

.popup {
  width: 330px;
  height: 180px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.75);
  grid-template-rows: repeat(3, 40px);
  grid-template-columns: repeat(6, 40px);
  padding: var(--card-padding);
}
