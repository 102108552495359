@import 'src/styles/imports';

.container {
  @include gappedColumn(40px);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  height: 100%;
  width: 100%;

  > img {
    width: 120px;
  }

  .messaging {
    @include gappedColumn(8px);
  }
}
