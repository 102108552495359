@import 'src/styles/imports';

.centeringGrid {
  @media screen and (min-width: ($mobile-layout-width + 1)) {
    // desktop:
    // 3 column grid used to center updates cards while aligning 'back to top' button to the right of the updates
    // middle column is restricted to 800px
    display: grid;
    justify-content: center;
    grid-template-columns: 44px minmax(auto, 800px) 44px;
  }

  @media screen and (max-width: $mobile-layout-width) {
    overflow: visible; // need this for back to top button sticky positioning
  }
}

.updatesColumn {
  grid-column: 2; // center column (2 out of 3)
  scroll-margin-top: 140px; // ensures that updates is scrolled all the way to the top during scrollIntoView call
}

.backToTopButtonContainer {
  position: sticky;
  width: fit-content;
  transition: opacity 0.3s ease;

  &:has(:disabled) {
    opacity: 0;
  }

  @media screen and (min-width: ($mobile-layout-width + 1)) {
    // desktop:
    // sticky, 20px from bottom of viewport
    bottom: 20px;
    align-self: end;
  }

  @media screen and (max-width: $mobile-layout-width) {
    height: 0;
    bottom: -40px;
    left: calc(100dvw - 40px);
  }
}

.backToTopButton {
  @media screen and (min-width: ($mobile-layout-width + 1)) {
    // desktop:
    // offset 20px to the right of the left edge of the updates column
    left: 20px;
  }

  @media screen and (max-width: $mobile-layout-width) {
    left: -5px;
    top: -43px;
  }
}

.heading {
  margin-bottom: 2rem;
  padding-inline: 0.5rem;
  color: var(--canvas-text-color);
}

.dueSoonAccordion {
  margin-bottom: 4rem;

  :global(.subHeader) {
    h3 {
      font-size: clamp(2rem, 2vw, 2.4rem);
      color: var(--canvas-text-color);
      padding-inline: 0.5rem;
    }

    svg {
      stroke: var(--canvas-text-color);
    }
  }
}

.dueSoonWrapper {
  padding-top: 2rem;
}

.updatesWrapper {
  margin-bottom: 6rem;
  padding-inline: 0.5rem;
  padding-bottom: 0.5rem;
}

.loadMoreWrapper {
  text-align: center;
  padding-bottom: 5rem;
  color: var(--canvas-text-color);

  @media screen and (max-width: $mobile-layout-width) {
    padding-bottom: 0;
  }
}

.scrollDetector {
  position: absolute;
  width: 100%;
  pointer-events: none;
}
