@import 'src/styles/imports';

.pageChanger {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: $nav-container-height;
  bottom: 0;
  width: 60px;
  height: auto;
  z-index: 1;
  backdrop-filter: blur(1px);
  cursor: pointer;
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  border: 0;

  &.left {
    left: 0;

    svg {
      right: 2px;
    }
  }
  &.right {
    right: 0;

    svg {
      left: 2px;
    }
  }

  &:hover,
  &:focus-visible {
    opacity: 0.8;
  }
  &:focus-visible {
    outline-offset: -2px;
    border-radius: 8px;
  }
}

@media (hover: none) {
  .pageChanger {
    display: none;
  }
}
