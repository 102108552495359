@import 'src/styles/imports';

.container {
  padding-bottom: 1.6rem;

  @media screen and (max-width: $mobile-layout-width) {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 0.8rem;
  }

  .filterOptions {
    border-bottom: 1px solid var(--card-border-color);

    @media screen and (max-width: $mobile-layout-width) {
      flex-grow: 1;
      overflow: auto;
    }

    .optionSection {
      padding: 0.8rem 0;
    }

    .label {
      padding: 0 2rem;
      color: var(--card-text-secondary-color);
    }
  }

  .selectAllButton {
    // remove default button styling
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;
    // custom styling
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 2rem;
    width: 100%;
    margin-top: 0.8rem;
    &:hover {
      background-color: var(--card-active-bg-color);
    }
    &:focus-visible {
      outline: 2px solid var(--card-input-focused-border-color);
      outline-offset: -2px;
    }
  }
}
