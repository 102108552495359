@import 'src/styles/imports';

.customTheme {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0.8rem 2rem 2rem;
  overflow-x: auto;
  @include customScrollbar;

  @media screen and (min-width: ($mobile-layout-width + 1px)) {
    max-height: 400px;
  }

  [data-key-nav-group^='custom-theme-row'] {
    padding-bottom: 4px;

    &:focus-visible {
      outline-offset: 4px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;

    .colorSelectors {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;

      .selected {
        box-shadow: 0 0 0 2px var(--card-cta-bg-color);

        &.additionalBorder {
          border: 1px solid var(--card-border-color);
        }
      }

      & button {
        height: 3.6rem;
        width: 3.6rem;
        border-radius: 1.2rem;
        border: 1px solid var(--card-bg-color);

        &:hover {
          cursor: pointer;
        }

        &:focus-visible {
          outline-offset: 6px;
        }
      }
    }
  }

  .background {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .backgroundSelectors {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .previewBlock,
    img {
      height: 4.5rem;
      border-radius: 1.2rem;
      width: 6.1rem;

      &:hover {
        cursor: pointer;
      }
    }
    .previewBlock {
      pointer-events: none;
    }
    img {
      display: block;
      object-fit: cover;
    }

    .selected {
      box-shadow: 0 0 0 2px var(--card-cta-bg-color);
      border-radius: 1.3rem;
    }

    button {
      place-self: center center;
      border: 1px solid var(--card-bg-color);
      padding: 0;
      background: none;
      border-radius: 1.2rem;

      &:focus-visible {
        outline-offset: 6px;
      }
    }
  }
}

.WallpaperButton {
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translate(0, -2px);
  }
}
