@import 'src/styles/imports';

.topSection {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 1px solid var(--card-border-color);
  border-radius: 100%;

  svg {
    top: 2px;
  }
}

.publishDate {
  color: var(--card-text-secondary-color);
}

.tags {
  display: flex;
  gap: 1rem;

  @media screen and (max-width: $small-mobile-layout-width) {
    flex-direction: column;
  }
}

.title {
  margin-bottom: 0.8rem;
}

.description {
  margin-bottom: 1.6rem;
}
