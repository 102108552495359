@import 'src/styles/imports';

.cell {
  color: var(--card-text-color);
  background-color: var(--card-bg-color);
  padding: 4px 8px;
  font-size: 16px;
  text-align: center;
  border: 0;
  transition: background-color 0.2s ease;

  @include media(S) {
    color: var(--canvas-text-color);
    background-color: transparent;
  }

  // sub label text element
  .text {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    height: 28px;
    width: 32px;
    line-height: 28px;
    padding: 0 4px;
    margin: 0 auto;

    &[data-today],
    &[data-today][data-selected] {
      color: var(--canvas-text-color);
      background-color: var(--canvas-bg-color);
      border-radius: 4px;

      @include media(S) {
        color: var(--card-text-color);
        background-color: var(--card-bg-color);
      }
    }

    &[data-selected] {
      background-color: var(--card-bg-color);
      border-radius: 3px;

      @include media(S) {
        background-color: var(--canvas-bg-color);
        color: var(--canvas-text-color);
      }
    }
  }

  // button variant
  &[type='button'] {
    cursor: pointer;

    &:focus-visible {
      outline-offset: -2px;
    }
  }

  // data variants
  &[data-not-interactive] {
    .text:hover {
      background-color: transparent;
      border-radius: 4px;
    }
  }
  &[data-display-date] {
    // - lower layer with background
    background-color: var(--card-input-focused-bg-color);

    @include media(S) {
      background-color: var(--card-cta-active-bg-color);
    }
  }
  &[data-hovering-date]::before {
    // - middle layer with background on pseudo element
    content: '';
    // pseudo layer layout
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    font-size: 0;
    line-height: 0;
    // pseudo layer styles
    background-color: var(--card-input-focused-bg-color);

    @include media(S) {
      background-color: transparent;
    }
  }
  &[data-not-current-month] {
    color: var(--card-text-secondary-color);
  }
  &[disabled] {
    color: var(--card-border-color);
  }
  &[disabled],
  &[data-variant='TH'] {
    @include preventUserSelection;
  }

  // modify continuous strip with cap styles
  @mixin stripWithCaps($selector: null, $layer) {
    @if ($selector != null) {
      $radius: 6px;
      // start cap of a continuous strip
      &:nth-child(1 of #{$selector}) {
        @if ($layer == 'lower') {
          border-radius: $radius 0 0 $radius;
        } @else if ($layer == 'middle') {
          &::before {
            border-radius: $radius 0 0 $radius;
          }
        }
      }
      // end cap of a continuous strip
      &:nth-last-child(1 of #{$selector}) {
        @if ($layer == 'lower') {
          border-radius: 0 $radius $radius 0;
        } @else if ($layer == 'middle') {
          &::before {
            border-radius: 0 $radius $radius 0;
          }
        }
      }
      // single item strip, both start and end cap
      &:nth-child(1 of #{$selector}):nth-last-child(1 of #{$selector}) {
        @if ($layer == 'lower') {
          border-radius: $radius;
        } @else if ($layer == 'middle') {
          &::before {
            border-radius: $radius;
          }
        }
      }
    }
  }
  @include stripWithCaps([data-display-date], 'lower');
  @include stripWithCaps([data-hovering-date], 'middle');
}
