@import 'src/styles/imports';

.emptyPage {
  height: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--canvas-text-color);

  @media screen and (max-width: $mobile-layout-width) {
    border: 1px solid rgba(0, 0, 0, var(--opacity-canvas-dimming));
    border-radius: 1.8rem;
    min-height: 70vh;
    padding: 0;
  }

  .content {
    text-align: center;
    height: fit-content;

    // offset nav and page header to make this visually "center aligned"
    margin-bottom: $page-container-top-padding;
    @include gappedColumn(1rem);

    @media screen and (max-width: $mobile-layout-width) {
      padding: 0;
    }

    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .editButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        > span {
          font-weight: 500;
          line-height: 24px;
          @media screen and (max-width: $mobile-layout-width) {
            display: none;
          }
        }
      }
    }
  }
}
