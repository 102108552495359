.widgetFloatingError {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 1rem 1rem;

  .errorCard {
    padding: 0;
  }
}
