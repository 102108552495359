@import 'src/styles/imports';

.iconMenu {
  display: flex;
  width: fit-content;
  flex-direction: column;
  height: 30rem;
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  border-radius: var(--card-border-radius);
  transition: background-color 0.3s;
  padding: 2rem 0;
  @include gappedColumn(2rem);
  box-shadow: shadow();
  border-radius: 1.6rem;
  background-color: var(--card-bg-color);

  .iconGroups {
    height: fit-content;
    overflow-x: hidden;
    overflow-y: scroll;
    @include gappedColumn(2rem);

    .iconGroup {
      padding: 0 2rem;
      display: flex;
      @include gappedColumn(0.5rem);

      label {
        color: var(--card-text-secondary-color);
        padding-bottom: 1rem;
      }

      .icons {
        width: fit-content;
        display: grid;
        grid-template-columns: repeat(6, 3.6rem);
        gap: 0.5rem;
        justify-items: center;
        align-items: center;

        .iconButton {
          padding: 0;
          appearance: none;
          background: none;
          border: 2px solid var(--card-bg-color);
          border-radius: var(--card-input-border-radius);

          &:hover {
            cursor: pointer;
          }

          &.selected {
            border: 2px solid var(--card-cta-bg-color);
            background-color: var(--card-active-bg-color);
          }

          &:focus-visible {
            outline: 2px solid var(--card-input-focused-border-color);
            outline-offset: 1px;
          }
        }
      }
    }
  }

  .actions {
    width: 100%;
    padding: 0 2rem;
    justify-content: flex-end;
    display: flex;
    gap: 1rem;
  }
}
