.label {
  font-size: 1.4rem;

  &.card {
    font-size: 1.6rem;
    color: var(--card-text-secondary-color);
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &.card {
    margin-top: 0.8rem;
  }
}

.examButton {
  text-align: left;
  padding: 1.2rem 3.5rem 1.2rem 1.2rem;
  border-radius: 1.6rem;
  border: 1px solid var(--canvas-cta-bg-color);
  width: 100%;
  cursor: pointer;

  &:link,
  &:visited {
    color: var(--canvas-cta-bg-color);
  }

  & .read {
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
    line-height: normal;
  }

  & .mainText {
    font-weight: 500;
  }

  svg {
    position: absolute;
    color: var(--canvas-cta-bg-color);
    right: 0.8rem;
    bottom: 0.8rem;
  }

  &.card {
    border: 1px solid var(--card-border-color);

    &:link,
    &:visited {
      color: var(--card-text-color);
    }

    svg {
      color: var(--card-text-color);
    }
  }
}
