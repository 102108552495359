.icon {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 3.2rem;
  line-height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  // margin: 0 auto;

  svg {
    stroke: none;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
