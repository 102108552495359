@import 'src/styles/imports';

.library {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabsContainer {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
  }

  .contentContainer {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    overflow-x: hidden;
    @include customScrollbar;

    .itemList {
      // remove default list styling
      list-style-type: none;
      padding: 0;
      margin: 0;

      // custom styling

      .itemButton {
        padding: 8px;
        border-radius: 8px;
        display: block;
        width: 100%;
        transition: background-color 0.3s ease;

        &.active {
          background-color: var(--card-active-bg-color);
        }

        &:hover {
          cursor: pointer;
          background-color: var(--card-input-focused-bg-color);
        }

        &:focus-visible {
          outline: 2px solid var(--card-input-focused-border-color);
        }

        .itemContainer {
          display: flex;
          gap: 12px;

          &.vertical {
            flex-direction: column;
          }

          .itemThumbnailContainer {
            flex: 0 0 100px;
            width: 100px;
            height: 140px;

            & img {
              border-radius: 8px;
              width: 100%;
              height: 100%;
            }
          }

          .itemInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
              font-size: 18px;
              font-weight: bold;
              @include lineClamp(2);
            }

            .requestInfo {
              display: flex;
              flex-direction: column;

              .pickup {
                color: var(--card-text-secondary-color);
              }
            }
          }
        }
      }
    }
  }

  .fees {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 8px;

    .sum {
      font-size: 18px;
      font-weight: bold;
      color: var(--card-text-color);
    }
  }
}

.wrapper {
  display: flex;
  padding: 20px 0;
}

.popup {
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  .header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heading {
      &:focus-visible {
        outline: 0;
      }
    }

    .close {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;

    @include customScrollbar;

    .itemName {
      font-size: 18px;
      font-weight: bold;
    }

    .detailsAccordion {
      // overwrite custom accordion's styling

      :global(.accordionItems) {
        margin: 0;
      }

      :global(.subHeader) {
        color: var(--card-text-color);
      }

      :global(.childrenContent) {
        padding: 10px 0;
      }
    }

    .detailsContent {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .notesList {
        margin: 0;
        list-style-type: disc;
      }
    }
  }

  .action {
    padding: 0 20px;
  }
}

.unstyledButton {
  // remove default button styling
  outline: none;
  font: inherit;
  color: inherit;
  padding: 0;
  border: none;
  background: none;
  text-align: start;
}

.subheading {
  font-size: 16px;
  color: var(--card-text-secondary-color);
  margin-bottom: 8px;
}

.text {
  font-size: 16px;
}

.centreContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.noData {
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 20px;
}

.error {
  height: 100%;
}
