@import 'src/styles/imports';

.assessments {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  .days {
    height: 100%;
    width: 100%;
    padding: 0;
  }

  .noAssessments {
    align-items: center;
    text-align: center;
    padding-bottom: 2rem;
    @include gappedColumn(2rem);
    > img {
      width: clamp(60px, 65%, 200px);
    }
  }
}
