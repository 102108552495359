.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

.image {
  width: 324px;
  margin: 5rem auto 2rem;
}

.text {
  text-align: left;
}
