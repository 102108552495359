.pages {
  width: 100%;
  padding: 0;
  margin: 0;
}

.page {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
}
.pageLabel {
  display: flex;
  justify-content: space-between;
  color: var(--card-text-secondary-color);
  & span {
    font-size: 1.4rem;
  }
}
.DragOverlayWrapper {
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(#000, 0.2);
}

.modalAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  .modalButtons {
    display: flex;
    gap: 2rem;
  }
}
