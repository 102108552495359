@import 'src/styles/imports';

$title-line-height: 2.4rem;

.day {
  display: flex;
  flex-direction: column;
  @include gappedColumn(2rem);

  .dayWithAssessments {
    padding-bottom: 2rem;
    list-style: none;

    .currentDate {
      font-weight: 900;
    }

    .items {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;

      .item {
        display: grid;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border-radius: 8px;
        margin: 0 -1rem;
        appearance: none;
        border: none;
        background: none;
        text-align: start;
        color: var(--card-text-color);
        grid-template-columns: 6.5rem min-content auto min-content;
        grid-template-rows: 1fr;

        .accent {
          height: 100%;
        }

        .titleGroup {
          overflow: hidden;
          @include gappedColumn(5px);
          padding-right: 1rem;

          // title and workshop text
          > * {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: $title-line-height;
            white-space: nowrap;
          }

          abbr {
            text-decoration: none;
          }
        }

        // status or grade
        .keyInfo {
          align-self: flex-start;
          line-height: $title-line-height;
          justify-self: end;
          white-space: nowrap;
          overflow: hidden;

          .gradePlaceholder {
            letter-spacing: 0.1rem;
            padding-left: 1rem;
          }
        }

        .timeInterval {
          display: flex;
          flex-direction: column;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--card-active-bg-color);
        }

        &.active {
          background-color: var(--card-active-bg-color);
        }

        // "small" layout styling
        &.small {
          grid-template-columns: min-content minmax(auto, 1fr) auto;

          .accent {
            grid-area: 1 / 1 / 3 / 1;
          }

          .titleGroup {
            grid-area: 1 / 2 / 1 / 4;
          }

          .status {
            grid-area: 2 / 3 / 2 / 4;
            max-width: 100%;
            text-overflow: ellipsis;
          }

          .dueTime {
            grid-area: 2 / 2 / 2 / 3;
          }

          .timeInterval {
            text-wrap: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
