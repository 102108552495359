.inEditShortcut {
  display: grid;
  grid-template-columns: 3.6rem 4.6rem 1fr 1fr 4rem;
  gap: 1.6rem;
  align-items: center;

  // for text input and error messages
  > span {
    overflow: hidden;
    white-space: noWrap;
  }
}
