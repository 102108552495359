@import 'src/styles/imports';

.bannerContainer {
  // Added padding specifically for the mobile upcoming page because of the inconsistent padding situation of the page container
  // TODO: remove banner container and clean up page container
  &.upcoming {
    // desktop needs extra margin-top to avoid banner from being clipped
    margin-top: $page-container-top-padding;
    @include media(S) {
      margin-top: 0px;
      padding: 0 16px;
    }
  }

  .banner {
    width: 100%;
    height: 50vh;
    min-height: 360px;
    max-height: 700px;
    padding: 3rem 4rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    border-radius: var(--card-border-radius);
    overflow-x: hidden;
    overflow-y: scroll;
    color: var(--global-text-color);
    @include customScrollbar;

    .background {
      top: 0;
      left: -40px;
      height: 100%;
      width: calc(100% + 40px);
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-position-y: 40%;
      background-size: cover;
      animation: slide-left-in ease-in-out 1s forwards;
    }

    @include media(M) {
      height: 360px;
    }

    @include media(S) {
      height: fit-content;
      min-height: 0;
      padding: 2rem;
      gap: 2rem;
    }

    .dismiss {
      width: fit-content;
      margin-left: auto;
    }

    .main {
      width: 100%;
      height: 100%;
      gap: 4rem;
      display: flex;
      justify-content: flex-end;
      overflow-y: hidden;

      @include media(S) {
        gap: 2rem;
        flex-direction: column;
      }

      .animationContainer {
        width: 60%;
        height: 100%;
        position: absolute;
        left: 0;

        @include media(S) {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 100%;
          height: 20vh;
        }

        .animation {
          width: 80%;

          @include media(S) {
            width: 100%;
          }
        }
      }

      .illustrationContainer {
        width: 50%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include media(S) {
          width: 100%;
          height: 20vh;
        }
      }

      .content {
        height: 100%;
        width: 50%;
        align-items: flex-end;
        display: flex;
        gap: 2rem;
        flex-direction: column-reverse;

        @include media(S) {
          width: 100%;
        }

        .text {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-self: flex-start;
          justify-content: center;
          gap: 1rem;

          @include media(M) {
            padding-top: 2rem;
            justify-content: flex-start;
            overflow-y: scroll;
            @include customScrollbar;
          }

          @include media(S) {
            height: 25vh;
            justify-content: flex-start;
            padding: 0 2rem;
            overflow-y: scroll;
            @include customScrollbar;
          }

          .title {
            font-size: 2.4rem;
            letter-spacing: -0.6px;
            line-height: 120%;
            font-weight: 700;

            @include media(S) {
              line-height: normal;
            }
          }

          > p {
            margin: 0;
            max-width: 72ch;
          }

          .dots {
            display: flex;
            gap: 1rem;
            padding-top: 1rem;

            .dot {
              height: 0.6rem;
              width: 0.6rem;
              background: var(--card-bg-color);
              border-radius: 100%;

              &.filled {
                background: var(--card-cta-bg-color);
              }
            }
          }
        }

        .nav {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          white-space: nowrap;

          // previous and next button styling
          .iconButton {
            height: 3.6rem;
            width: 3.6rem;
            justify-content: center;
            padding: 0;
          }
        }
      }
    }
  }
}

@keyframes slide-left-in {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
