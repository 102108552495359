.status {
  color: var(--light-text, #333);

  &.canvas {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
  }
}

.yellow {
  &.canvas {
    background-color: var(--color-intent-warning);
  }

  &.card {
    color: var(--color-intent-warning);
  }
}

.green {
  &.canvas {
    background-color: var(--color-intent-success);
    color: white;
  }

  &.card {
    color: var(--color-intent-success);
  }
}
