@import 'src/styles/imports';

$highlight-colour: var(--card-cta-active-bg-color-alt);
$label-text-colour: var(--card-cta-text-color-alt);

.ImportantDates {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 2rem;
  margin: 0;
  list-style: none;

  &:focus-visible {
    outline: 2px solid var(--card-input-focused-border-color);
    border-radius: 4px;
  }

  .monthLabel {
    display: block;
    height: 100%;
    position: absolute;
    top: 0px;

    .stickyContainer {
      background-color: var(--card-bg-color);
      position: sticky;
      top: 0px;
      width: 100%;
      z-index: 2;
      font-size: 2.4rem;
      line-height: 3.4rem;
      font-weight: bold;
      text-align: right;
    }
  }

  li {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    .monthList {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding: 0;

      .importantDateItem {
        width: 100%;
        .descriptionContainer {
          width: 100%;
          display: flex;
          gap: 1rem;
          flex: 1;
          align-self: stretch;
          word-break: break-word;

          &:before {
            content: '';
            display: block;
            flex: 0 0 6px;
            background-color: $highlight-colour;
            border-radius: 99px;
          }

          .descriptionGroup {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            .description {
              display: flex;
              flex-direction: column;
              gap: 0.8rem;

              .highlightedLabel {
                display: inline-block;
                padding: 2px 4px;
                border-radius: 4px;
                background-color: $highlight-colour;
                color: $label-text-colour;
                font-size: 1.6rem;
                line-height: 1.6rem;
                font-weight: 500;
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.noLocation {
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  @include gappedColumn(10px);
}
