@import 'src/styles/imports';

.DraggableWindow {
  position: fixed;
  pointer-events: none;
  z-index: $layer-draggable-window;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  .container {
    box-shadow: shadow();
    border-radius: 1.6rem;
    background-color: var(--card-bg-color);
    padding-top: 1.6rem;
    opacity: 0;
    transform: translate(0, -10px);
    transition: opacity 0.3s, transform 0.3s ease;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    backdrop-filter: var(--card-backdrop-filter);
    display: flex;
    flex-direction: column;

    &[data-fullscreen] {
      width: 100vw;
      height: 100vh;
      height: 100dvh;
      border-radius: 0;
      > .header {
        cursor: default;
      }
    }

    @media screen and (max-width: $mobile-layout-width) {
      padding-top: 0.8rem;
    }
  }

  &[data-active] {
    pointer-events: all;

    .container {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--card-text-color);
    cursor: move;
    padding: 0rem 2rem;
    flex: 0 1 auto;

    .title {
      display: flex;
      overflow: hidden;
    }

    .closeButton {
      &:not(:focus-visible) {
        outline: none;
      }

      &:focus-visible {
        outline-width: 2px;
        outline-offset: -2px;
        border-radius: 50%;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
