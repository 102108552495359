.container {
  color: var(--canvas-text-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  .illustration {
    width: clamp(140px, 80%, 240px);
    svg {
      width: 100%;
    }
  }
}
