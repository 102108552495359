@import 'src/styles/imports';

.editMenuContainer {
  .editMenu {
    display: flex;
    flex-direction: column;
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    transition: background-color 0.3s;
    padding: 1rem 0 2rem;
    width: 280px;

    label {
      color: var(--card-text-secondary-color);
    }
  }

  .headingText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.editMenuMobile {
  height: 100%;
  width: 100%;

  .widgetPreview {
    height: 100%;
    width: 100%;
    max-height: 50vh;
    padding: 0 2rem 2rem;

    .widgetCardContainer {
      height: 100%;
      width: calc(100% - 4rem);
      max-height: 40vh;
      border-radius: var(--card-border-radius);
      box-shadow: 4px 30px 32px rgba(#000, 0.3);
      border: 1px solid var(--card-border-color);
      position: fixed;
    }
  }
  .widgetOptions {
    height: 100%;
    top: calc(-10vh - 4rem);
    padding: 2rem 0;
    background-color: var(--card-bg-color);
    box-shadow: 0px -8px 50px -12px rgba(0, 0, 0, 0.15);
    backdrop-filter: var(--card-backdrop-filter);
  }
}

.editItems {
  width: 100%;
  @include gappedColumn(1rem);

  > button {
    padding: 1rem 2rem;
    width: 100%;
    line-height: 150%;
    font-size: var(--global-font-size);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    white-space: nowrap;
    appearance: none;
    background: none;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: var(--card-cta-active-bg-color-alt);
    }

    &:focus-visible {
      outline: 2px solid var(--card-input-focused-border-color);
    }
    svg {
      stroke: currentColor;
    }
  }
}
