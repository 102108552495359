@import 'src/styles/imports';

.custom {
  height: 100%;
  overflow-x: hidden;
  @include customScrollbar;

  .widgetBoard {
    height: 100%;

    .editBoard {
      opacity: 0.6;

      .rows,
      .columns {
        position: absolute;
        list-style: none;
        margin: 0;

        .line {
          position: absolute;
          transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          animation: fadeIn ease-in-out 0.3s forwards;
        }

        &.dragging {
          transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          opacity: 0.3;
        }
      }

      .rows {
        width: auto;

        // use small enough offset to prevent content overflowing
        left: -30px;
        right: -30px;
        @media screen and (max-width: $mobile-layout-width) {
          left: 0;
          right: 0;
        }

        .line {
          left: 0;
          width: 100%;
          height: 1px;
          // custom dash
          background-image: linear-gradient(
            to right,
            transparent 25%,
            var(--canvas-text-color) 25%,
            var(--canvas-text-color) 75%,
            transparent 75%
          );
          background-position: center center;
          background-size: 16px 2px;
          background-repeat: repeat-x;
        }
      }

      .columns {
        height: 100%;
        width: 100%;

        .line {
          height: 100%;
          width: 1px;
          top: -40px;
          background-image: linear-gradient(
            var(--canvas-text-color) 50%,
            transparent 0%
          );
          background-position: right;
          background-size: 2px 16px;
          background-repeat: repeat-y;
          mask-image: linear-gradient(
            transparent,
            var(--canvas-text-color) 40px,
            var(--canvas-text-color) calc(100% - 40px),
            transparent 100%
          );
          -webkit-mask-image: linear-gradient(
            to bottom,
            transparent,
            var(--canvas-text-color) 40px,
            var(--canvas-text-color) calc(100% - 40px),
            transparent 100%
          );
        }
      }
    }

    .board {
      display: grid;
      justify-content: flex-start;
      gap: 3rem;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      padding-bottom: 40px;

      @include media(M) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @include media(S) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      [data-key-nav-group^='select-widget'] {
        &:focus-visible {
          outline-offset: 4px;
        }
      }

      .dropArea {
        border-radius: var(--card-border-radius);
        background-color: var(--canvas-cta-active-border-color);
        height: 100%;
        width: 100%;
      }
    }
  }
}
