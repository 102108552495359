@import 'src/styles/imports';

.widgetContainer {
  width: 100%;
  height: 100%;
  min-height: 240px;
  max-height: min(600px, 50vh);
  padding: 2px;
  border-radius: var(--card-border-radius);
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1), outline 0s,
    outline-offset 0s, border-radius 0s;

  &.inEditMode {
    cursor: grab;

    &:hover {
      outline: 2px solid var(--card-bg-color);
      outline-offset: 2px;
    }
  }

  &[data-edit-menu-shown='true'] {
    outline: 2px solid var(--canvas-input-focused-border-color);
    outline-offset: 2px;
    border-radius: 20px;
  }
}

.widgetCard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &.overlay {
    box-shadow: 4px 30px 32px rgba(#000, 0.3);
  }

  .scrollContainer {
    border-radius: var(--card-border-radius);
    display: flex;
    flex-direction: column;
    height: 100%;
    @include customScrollbar;

    &.inert {
      opacity: 0.4;
    }

    .widget {
      flex: 1;
      height: 100%;
    }
  }
}
