@use '../../../../../../styles/imports';

.container {
  width: 40rem;
}

.contentWrapper {
  @media screen and (max-width: imports.$mobile-layout-width) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.searchWrapper {
  padding: 0.8rem 1.6rem;
}

.title {
  font-weight: bold;
  padding: 0.8rem 1.6rem;
}

.pageList {
  overflow: auto;
  height: 16rem;

  @media screen and (max-width: imports.$mobile-layout-width) {
    flex-grow: 1;
  }
}

.noResults {
  padding: 0.8rem 1.6rem;
}

.footerBar {
  display: flex;
  @include imports.gappedRow(1rem);
  justify-content: flex-end;
  border-top: 1px solid var(--card-border-color);
  padding: 1.6rem;
}
