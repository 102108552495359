.stack {
  display: flex;

  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
}
