@import 'src/styles/imports';

div.relativePanelCard {
  display: flex;
  padding: 10px 0;
}

.scheduleCard {
  display: flex;
  flex-direction: column;
  height: 100%;

  z-index: 100;
  width: 360px;

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .scheduleCardSection {
    padding: 10px 20px;
  }

  .closeButton {
    margin: -8px -8px 0 0;
    color: var(--card-text-color);
  }

  .label {
    width: 0;
    min-width: 100%;

    font-size: 1.6rem;
    color: var(--card-text-secondary-color);
    margin-bottom: 0.8rem;
  }

  .content {
    @extend .scheduleCardSection;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.6rem;
    padding-bottom: 20px;
    @include customScrollbar;
  }

  // mobile views
  @media screen and (max-width: $mobile-layout-width) {
    .content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .keyDate {
    @extend .content;
    padding-bottom: 10px;
    color: var(--card-text-color);
  }

  .actions {
    @extend .scheduleCardSection;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  @media screen and (max-width: $mobile-layout-width) {
    .actions {
      padding: 10px 0 0;
    }
  }
}

@media screen and (max-width: $mobile-layout-width) {
  .scheduleCard {
    width: 100%;
  }
}

.classHeader {
  @extend .scheduleCardSection;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;

  .headerContent {
    flex: 1 1;

    @media screen and (max-width: $mobile-layout-width) {
      & p {
        font-weight: 400;
      }
    }
  }
}

.disclaimer {
  display: flex;
  align-items: flex-start;
  line-height: 16px;
  gap: 0.4rem;
  color: var(--card-text-secondary-color);
  font-size: 14px;
}
