.pageItem {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  font-size: 1.6rem;
  border: 0;
  width: 100%;
  background: none;
  cursor: pointer;
}

.pageName {
  display: flex;
  gap: 0.4rem;

  text-align: left;
  word-break: break-word;
  overflow: hidden;
  span {
    color: var(--card-text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
  svg {
    stroke: var(--card-text-color);
  }
}

.hiddenLabel {
  font-size: 1.4rem;
  color: var(--card-text-secondary-color);
  padding-right: 0.4rem;
}
.disabled {
  background-color: var(--card-cta-disabled-text-color);
  color: var(--card-text-secondary-color);
  cursor: default;
}
