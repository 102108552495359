.trigger {
  // remove default button styling
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  // custom styling
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.8rem 2rem;
  width: 100%;

  .text {
    text-align: left;
  }

  & svg {
    flex-shrink: 0;
  }

  &:hover {
    background-color: var(--card-active-bg-color);
  }

  &:focus-visible {
    outline: 2px solid var(--card-input-focused-border-color);
    outline-offset: -2px;
  }
}
