@import '../../../styles/imports';

.Main {
  color: var(--card-text-color);
  height: 100%;
  width: 100%;
  overflow: hidden;

  // Main container display area placeholder
  &[id='horizontalMain']::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
  }
}

.VerticalMain {
  color: var(--card-text-color);
  height: 100%;
  width: 100%;
  padding-bottom: 10rem;
  .verticalPage {
    position: relative;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .inactivePage {
    opacity: 0.4;
  }
  @include mobileCustomScrollbar;
}

.page {
  position: absolute;
  left: 70px;
  top: 0;
  height: 100%;
  width: calc(100% - 140px);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  @include customScrollbar;

  &[data-active] {
    pointer-events: all;

    .overlay {
      opacity: 0;
      pointer-events: none;
    }
  }

  &[data-hidden] {
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (max-width: 1200px) {
    left: 4vw;
    width: calc(100% - 8vw);
  }

  @media screen and (max-width: $mobile-layout-width) {
    left: 0;
    width: 100%;
  }

  // Page: Schedule
  &[data-page='schedule'] {
    // Note: schedule view scroll disabled, only the calendar schedule container is scrollable
    overflow: hidden;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
