@import 'src/styles/imports';

.menu {
  > button {
    white-space: nowrap;
  }

  svg {
    pointer-events: none;
  }
  .menuList {
    display: flex;
    flex-direction: column;
    // TODO: outline thickness variable for offset
    gap: 2px;
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    border: var(--card-border-style) var(--card-border-width)
      var(--card-border-color);
    transition: background-color 0.3s;
    box-shadow: $shadow-xl;
    padding: 1rem;
    backdrop-filter: var(--card-backdrop-filter);

    .menuListItem {
      width: 100%;
      list-style: none;
      line-height: 150%;
      padding: 1rem;
      font-size: var(--global-font-size);
      color: var(--card-text-color);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      white-space: nowrap;

      appearance: none;
      background: none;
      border: none;

      &.submenuTrigger {
        justify-content: space-between;

        // invisible pseudo-element to extend the submenu trigger's hover range
        // to bridge the gap between parent and child menus
        &::before {
          position: absolute;
          content: '';
          height: 100%;
          width: calc(100% + 20px);
        }

        .label {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: var(--card-input-focused-bg-color);
      }

      svg {
        stroke: currentColor;
      }
    }
  }
}
