@import 'src/styles/imports';

.editLinkBackground {
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  border-radius: var(--card-border-radius);
  transition: background-color 0.3s;
  max-width: 100vw;
  width: 40rem;

  @media screen and (max-width: $mobile-layout-width) {
    height: 100%;
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
  }

  .content {
    width: 100%;
    padding: 2rem;
    @include gappedColumn(2rem);

    @media screen and (max-width: $mobile-layout-width) {
      height: 100%;
    }

    .item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      label {
        color: var(--card-text-secondary-color);
      }
    }
    .loading {
      display: flex;
      align-items: center;
      color: var(--card-cta-bg-color);
    }
  }

  .actions {
    width: 100%;
    justify-content: space-between;
    display: flex;
    gap: 1rem;

    @media screen and (max-width: $mobile-layout-width) {
      padding: 1rem 2rem;
    }

    > div {
      display: flex;
      gap: 2rem;
    }
  }
}
