.tabs {
  width: fit-content;
  margin-bottom: -1px;
  display: flex;
  align-items: center;

  menu {
    padding: 0;
    margin: 0;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      padding: 0;
      margin: 0;
      list-style: none;

      // selected tab underline
      &::after {
        $height: 2px;
        content: '';
        position: absolute;
        bottom: -$height;
        font-size: 0;
        line-height: 0;
        height: $height;
        overflow: hidden;
        background: var(--canvas-text-color);
        transition-property: left, width, background-color;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

        // controlled by JS via CSS vars
        display: var(--nav-tabs-underline-display);
        width: var(--nav-tabs-underline-width);
        left: var(--nav-tabs-underline-left);
      }
    }

    svg {
      fill: var(--canvas-text-color);
    }
  }
}
