@import 'src/styles/imports';

.Themer {
  @media screen and (min-width: ($mobile-layout-width + 1px)) {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: $mobile-layout-width) {
    height: 100%;
  }

  span {
    font-size: 1.6rem;
    color: var(--card-text-color);
  }

  span:first-letter {
    text-transform: uppercase;
  }

  svg {
    stroke: var(--card-text-color);
  }

  .container {
    z-index: 2;
    border-radius: 1.6rem;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    @media screen and (min-width: ($mobile-layout-width + 1px)) {
      width: 40rem;
      max-height: 600px;
    }
    @media screen and (max-width: $mobile-layout-width) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .selector > div > div {
      padding-left: 2rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--card-text-color);
    cursor: move;
    padding: 0 2rem;
    button {
      padding: 0;
      border: none;
      outline: none;
      background-color: transparent;
      display: grid;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
