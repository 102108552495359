@import 'src/styles/imports';

.hiddenTabsMenu {
  white-space: nowrap;
  // MANUAL OFFSET FOR HUMAN EYES
  .numberButton {
    height: 28px;
    border-radius: 4px;
  }
  button {
    appearance: none;
    background: none;
    border: none;
    padding: 0 15px;

    &:focus-visible {
      border-radius: 40px;
    }

    &.underline {
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        height: 2px;
        left: 0;
        bottom: -2px;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        background: var(--canvas-text-color);
      }
    }
  }

  .hiddenTabsList {
    display: flex;
    width: 26rem;
    flex-direction: column;
    gap: 2px;
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    border: var(--card-border-style) var(--card-border-width)
      var(--card-border-color);
    transition: background-color 0.3s;
    box-shadow: $shadow-xl;
    padding: 1rem;

    .hiddenTab {
      width: 100%;
      min-width: 20rem;
      list-style: none;
      line-height: 150%;
      padding: 1rem;
      font-size: var(--global-font-size);
      color: var(--card-text-color);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      white-space: nowrap;
      justify-content: space-between;

      .textWrapper {
        display: flex;
        max-width: 100%;

        &.isSelected {
          max-width: 80%;
        }
      }

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--card-input-focused-bg-color);
      }

      &:focus-visible {
        border-radius: 12px;
        outline: 2px solid var(--card-input-focused-border-color);
      }

      svg {
        flex-shrink: 0;
        stroke: currentColor;
      }
    }
  }
}
