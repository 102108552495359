@import '../../../../styles/mixins';
@import 'src/styles/imports';

.CourseStatusHelp {
  color: var(--canvas-text-color);
  height: 100vh;
  overflow: hidden;

  &[data-banner-shown='UPCOMING'] {
    @media screen and (max-width: $mobile-layout-width) {
      height: auto;
    }
    & > div > div {
      padding: 0px;
    }
  }
  @media screen and (max-width: $mobile-layout-width) {
    height: 75vh;
  }

  // content wrapper
  .wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    @include customScrollbar();
  }

  // content
  .content {
    display: block;
    width: 50%;
    padding: 160px 0 40px 0;

    @media screen and (max-width: 1000px) {
      width: auto;
      padding: 80px 0;
    }
  }

  // content elements
  .heading {
    display: block;
    width: auto;
    margin: 0 auto 4rem auto;
    font-size: clamp(3rem, 4vw, 5rem);
    line-height: 1;
    letter-spacing: -0.02em;
    word-break: break-word;
    font-weight: 800;
  }

  p {
    display: block;
    margin: 2rem auto;
  }

  .actions {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    padding: 20px 0 0 0;
  }
}
