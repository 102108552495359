.warningBox {
  color: var(--canvas-text-color);
  border: 1px solid var(--canvas-text-color);
  padding: 0.8rem 2rem;
  border-radius: 1.6rem;

  a,
  a:visited,
  a:link {
    color: var(--canvas-text-color);
    text-decoration: underline;
  }
}
