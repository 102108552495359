@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.upcomingListingMobile {
  height: 100%;
  overflow: hidden;
}

.events {
  display: flex;
  gap: 16px;
  white-space: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding-left: 16px;
  padding-right: 16px;
}

.section {
  & > div:last-child {
    margin-right: 0px;
  }
}

.listingHeading {
  position: sticky;
  left: 0;
  width: max-content;
  color: var(--canvas-text-color);
  padding-bottom: 2rem;
  padding-right: 16px;
}

.eventCard {
  margin-right: 16px;
  padding: 16px;
  display: inline-block;
  width: calc(100vw - 64px); // 100vw - 4 * gap between cards
  max-width: 360px;
  overflow: hidden;
  scroll-snap-align: center;
  white-space: normal;

  &.noEventsToday {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    background-color: rgba(0, 0, 0, var(--opacity-canvas-dimming));
    color: var(--canvas-text-color);
  }
}

.eventCardContents {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.timeUntil {
  padding-bottom: 0.8rem;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  margin-top: 20px;
  font-size: 16px;
  @include customScrollbar;
}

.location {
  font-size: 1.4rem;
}

.unitTitle {
  margin-top: 5px;
}

.activeTitle {
  font-size: 24px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding-top: 0.4rem;
}

// classes for renderEventDetails
.label {
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
}

.largeTime {
  font-size: 1.6rem;
  font-weight: 600;
}

.warningBox {
  margin-inline: 1.6rem;
}

.assessmentsWarning {
  padding-block: 25px;
}

.disclaimer {
  display: flex;
  align-items: flex-start;
  line-height: 16px;
  gap: 0.4rem;
  opacity: 60%;
  font-size: 14px;
}
