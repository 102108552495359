@import 'src/styles/imports';

.selectTheme {
  padding: 0 0.8rem 2rem 2rem;
  @include customScrollbar;

  @media screen and (min-width: ($mobile-layout-width + 1px)) {
    max-height: 400px;
  }

  .themes {
    color: var(--card-text-color);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    [data-key-nav-group^='select-theme-row'] {
      padding-bottom: 2px;

      &:focus-visible {
        outline-offset: 4px;
      }
    }

    div {
      white-space: nowrap;
    }

    button {
      background-color: transparent;
      border: none;
      padding: 2px;
      margin-left: 0;

      &:hover {
        cursor: pointer;
      }

      &[active] {
        background-color: var(--card-bg-color);
        color: var(--card-text-color);
      }
    }
  }
}
