@import 'src/styles/imports';

.placeholder {
  // fixed height for auto scrolling
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  list-style: none;
  align-items: flex-end;

  .currentDate {
    font-weight: 900;
  }

  > img {
    max-width: 50%;
  }

  &.small {
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    @include gappedColumn(2rem);
    > img {
      height: 150px;
      width: 100%;
      max-width: none;
    }
  }
}
