@import '../../../../../styles/imports';

.mobileNavContainer {
  background-color: var(--canvas-bg-color);
  width: 100%;

  .headerContainer {
    padding: 0 1.6rem;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    height: 4rem;

    .headerTextWrapper {
      display: flex;
      max-width: calc(100% - 9rem);
    }

    .headerText {
      color: var(--canvas-text-color);
      font-weight: 800;
      font-size: 2.4rem;
      text-align: left;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 1.5rem;
    }

    .collapsibleViewToggle {
      transition: transform 0.3s ease;
      height: 24px;
      pointer-events: none;

      &[data-hide='false'] {
        transform: rotateZ(180deg);
      }
    }
  }

  .pageSetting {
    position: absolute;
    right: 5rem;
    top: 0;
    transition: opacity 0.3s ease;
    &[data-hide='true'] {
      opacity: 0;
      pointer-events: none;
      display: none;
    }
  }

  .listContainer {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1.6rem;

    & > li {
      position: relative;

      & > button {
        background-color: transparent;
        border: none;
        text-align: left;
        font-size: 1.6rem;
        cursor: pointer;
      }
    }

    .menuTextWrapper {
      padding-right: 1.5rem;
    }

    .menuItem {
      color: var(--canvas-text-color);
      font-weight: 800;
      display: block;
      width: 100%;
      padding: 0;
    }

    .selected {
      &:before {
        content: '';
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 1.6rem;
        border-radius: 0 4px 4px 0;
        background-color: var(--canvas-text-color);
      }
    }
  }
}

.navItemsContainer {
  overflow-y: auto;
  max-height: 40vh;
  @include customScrollbar;
}
