@import 'src/styles/imports';

// default clock colour
$clock-colour: var(--card-text-color);

// z-index
$LayerTop: 2;
$LayerShadow: -1;

.clock {
  height: 100%;
  width: 100%;

  .sizeContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 2rem 2rem;
    container-type: size;

    .frame {
      aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: var(--clock-colour);
      box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.25);
      // adding minimal width and height to fix compatibility issue
      min-height: 150px;
      min-width: 150px;

      .indicators {
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .markersPair {
          position: absolute;
          width: 85%;
          height: 0.75%;

          &::before,
          &::after {
            content: '';
            position: absolute;
            background-color: var(--clock-colour);
            height: 100%;
            width: 7%;
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
      }

      span {
        line-height: normal;
        color: var(--clock-colour);
        letter-spacing: -2px;
        font-weight: 500;

        @include media(L) {
          font-size: 2.8rem;
        }

        @include media(M) {
          font-size: 2rem;
        }

        @include media(S) {
          font-size: 1.8rem;
        }
      }

      .mid {
        height: 100%;
        width: 100%;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .face {
    // adding minimal width and height to fix compatibility issue
    min-height: 140px;
    min-width: 140px;
    width: 92.5%;
    height: 92.5%;
    border-radius: 100%;
    background-color: var(--card-bg-color);
    box-shadow: inset 25px 20px 40px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .hands {
      height: 100%;
      width: 100%;
      position: absolute;

      .hour {
        top: 25%;
        height: 35%;
        width: 2%;
        // half face width - half hand width (50% - 1%)
        left: 49%;
        background-color: var(--clock-colour);
      }

      .minute {
        top: 15%;
        width: 2%;
        height: 45%;
        background-color: var(--clock-colour);
        // half face width - half hand width (50% - 1%)
        left: 49%;
      }

      .second {
        background-color: var(--clock-colour);
        width: 0.6%;
        height: 45%;
        // half face width - half hand width (50% - 0.3%)
        left: 49.7%;
        top: 12.5%;

        &::before {
          content: '';
          position: absolute;
          background-color: var(--clock-colour);
          bottom: -26%;
          // half face width - half hand width (50% - 175%)
          left: -125%;
          height: 27%;
          width: 350%;
          clip-path: polygon(0 10%, 50% 0, 100% 10%, 100% 100%, 0% 100%);
        }
      }
    }
  }
}

.containerBox {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-origin: center;
}

.circleBottom {
  position: absolute;
  background-color: var(--clock-colour);
  height: 5%;
  width: 5%;
  // half face width - half circle width (50% - 2.5%)
  left: 47.5;
  border-radius: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.circleTop {
  position: absolute;
  z-index: $LayerTop;
  // half face width - half circle width / height (50% - 1.5%)
  top: 48.5%;
  left: 48.5%;
  height: 3%;
  width: 3%;
  border-radius: 100%;
  background-color: var(--card-text-secondary-color);
  background: radial-gradient(
    50% 50% at 50% 50%,
    var(--clock-colour) 0%,
    var(--clock-colour) 50%,
    rgba(255, 255, 255, 0.5) 100%
  );
  box-shadow: 0 0 4px var(--clock-colour),
    inset -2px -2px 4px rgba(0, 0, 0, 0.25);
}

.handShadow {
  top: 0;
  height: 100%;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  z-index: $LayerShadow;
  filter: blur(4px);

  &.long {
    height: 125%;
  }
}

@container (aspect-ratio >= 1) {
  .frame {
    height: 100%;
    width: auto;
  }
}

@container (aspect-ratio < 1) {
  .frame {
    width: 100%;
    height: auto;
  }
}
