@import 'src/styles/imports';

.additionalOptions {
  @include gappedColumn(2rem);

  .item {
    padding: 0 2rem;
    @include gappedColumn(1rem);
    &:last-child {
      padding-bottom: 2rem;
      border-bottom: 1px solid var(--card-border-color);
    }
  }
}
