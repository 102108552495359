@use 'sass:math';

@mixin card {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $grey-100;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

@function shadow($depth: 8, $size: 2, $softness: 2, $shade: rgba(#000, 0.03)) {
  $result: #{$size}px #{$size}px #{$size * 2}px $shade;

  @for $i from 1 to $depth {
    $result: $result,
      #{calc($size * $i / 2)}px
        #{$size *
        $i}px
        #{$size *
        $softness *
        $i}px
        $shade;
  }

  @return $result;
}

@mixin lineClamp($lines: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  overflow: hidden;
}

@mixin gappedColumn($gap: 20px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin gappedRow($gap: 20px) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin customScrollbar {
  // TODO: use standard scrollbar customisation CSS when wider support is available
  // Notes:
  // 1. currently used CSS rules are non-standard:
  // https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
  // 2. standard scrollbar customisation CSS:
  // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
  // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color

  $custom-scrollbar-width: 14px;
  overflow-y: auto;
  // Need to use parent selector:'&' to limit to applied nodes only
  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
  }
  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: math.div($custom-scrollbar-width, 2);
    background-color: #00000020;
  }
}

@mixin mobileCustomScrollbar {
  @include media(S) {
    scrollbar-width: none;
    @include customScrollbar;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@mixin preventUserSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
