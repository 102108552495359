@import 'src/styles/imports';

.testHarness {
  color: var(--card-text-color);
  text-align: right;
  .configureButton {
    background-color: var(--card-bg-color);
    border: none;
    border-radius: 100%;
    padding: 0;
    height: 4rem;
    width: 4rem;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    line-height: 0;
  }
}
