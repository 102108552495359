.overlay {
  position: fixed;
  width: inherit;
  z-index: 999;
  transition: opacity 0.3s ease;
  max-height: 90dvh;
  top: 5dvh;
  box-shadow: 0 25px 50px -8px rgba(0, 0, 0, 0.35);
  max-width: 400px;
}

.transitionPositions {
  transition-property: opacity, top, left, right;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.container {
  transition: all 0.3s ease;
}
