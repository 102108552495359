.pageHeading {
  height: 100%;
  width: fit-content;
  font-size: 3.2rem;
  line-height: 3.2rem;
  font-weight: 800;
  color: var(--canvas-text-color);
  overflow: hidden;

  // allow space for focus outline
  padding: 2px;
  margin: -2px;

  @media (hover) {
    &:hover {
      cursor: text;
    }
  }

  .heading {
    padding-block: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    &:focus-visible {
      outline: solid 2px;
      border-radius: 4px;
    }
  }
}
