.pageNameInput {
  //https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }

  > textarea {
    resize: none;
    overflow: hidden;
    font-size: 3rem;
    font-weight: 800;
    color: var(--canvas-text-color);
    background: none;
    padding: 0;
    border: none;
    outline: none;
    resize: none;

    &:focus-visible {
      outline: solid 2px;
      border-radius: 4px;
    }

    &::placeholder {
      opacity: 0.3;
    }
  }

  > textarea,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
    word-break: break-all;
    line-height: inherit;
    padding: 0;
  }
}
