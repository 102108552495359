@import '../../../../styles/imports';

.listingHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.9rem;

  h2 {
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--canvas-text-color);
  }
}

.settingsButton {
  width: 40px;
  height: 40px;
  justify-content: center;

  &:focus-visible {
    border-radius: 44px;
    outline-offset: 2px;
  }
  svg {
    pointer-events: none;
  }
}

.locationCard {
  position: absolute;
  right: 0;
  top: 35px;
  display: flex;
  width: 250px;
  justify-content: space-between;
  z-index: 2;
  font-size: 16px;
  align-items: center;
}
