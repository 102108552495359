@import '../../../styles/imports';

.loadingWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: $mobile-layout-width) {
    position: relative;
  }
}
