@import 'src/styles/imports';

.notes {
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column-start: 1;
  grid-column-end: 3;
  height: 100%;
  position: relative;
  justify-content: space-between;
  .widgetContent {
    height: 100%;
    position: relative;
    padding: 0 2rem;
    @include customScrollbar;
    & > div {
      height: 100%;
    }
    .placeholder {
      position: absolute;
      top: 2px;
      opacity: 0.6;
    }
    & > div > div {
      outline: none;
      height: 100%;
    }
  }

  /* Placeholder (at the top) */
  :global(p.is-editor-empty:first-child) {
    &::before {
      content: attr(data-placeholder);
      color: currentColor;
      opacity: 0.4;
      float: left;
      height: 0;
      pointer-events: none;
    }
  }

  :global(p) {
    word-break: break-all;
  }
}

.bubbleMenu {
  overflow-x: auto;
  width: 100%;
  padding: 0 12px 8px;
  display: flex;
  gap: 2px;
  @include customScrollbar;

  & > button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    &:hover,
    &.isActive {
      background-color: var(--card-active-bg-color);
      svg {
        stroke: (var(--card-active-bg-color));
        filter: invert(100%);
      }
    }
  }
}

ul[data-type='taskList'] {
  list-style: none;
  padding: 0;
  p {
    margin: 0;
  }

  li {
    display: flex;
    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}
