@import 'src/styles/imports';

.loading {
  display: flex;
  justify-content: center;
}
.links {
  --search-result-grid-col-count: 1; // default, calculated by JS
  display: grid;
  width: 100%;
  grid-template-columns: repeat(var(--search-result-grid-col-count), 1fr);
  gap: 20px;
  justify-content: stretch;
  align-items: stretch;

  &:focus-visible {
    outline-offset: 4px;
    border-radius: 12px;
  }
}
.resultSearchBar {
  width: 50vw;
  margin: 0 auto 20px;
  z-index: 2;
  @media screen and (max-width: $mobile-layout-width) {
    width: 100%;
  }
}
.searchResult {
  outline: none;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    color: var(--canvas-text-color);
    .filters {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
  .bubbleFilter {
    margin-bottom: 20px;
  }
}
.background {
  z-index: -1;
  height: 100%;
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
}

.searchContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .page {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 160px 80px;
    @include customScrollbar;
    @media screen and (max-width: $mobile-layout-width) {
      padding: 120px 20px;
    }
  }
}
.faq {
  margin: 20px 0;
}
