@import '../../../../styles/mixins';
@import 'src/styles/_variables.scss';

.ScheduleItemCardWrapper {
  height: 100%;
  border-radius: 12px;
  padding: 0;
  overflow: hidden;

  @media screen and (max-width: $mobile-layout-width) {
    border-radius: 8px;
  }
}

.ScheduleItem {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 4px 0 0 8px;

  .displayTime,
  .displayLocation {
    font-size: 1.6rem;
    line-height: 1.5;
    overflow: hidden;
    // default, allows one line
    white-space: nowrap;
  }

  .unitHeadingWrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
    width: 100%;

    h3.unitHeading {
      flex: 0 0 auto;
      width: auto;
      max-width: calc(100% - 12px); // cater for Accent and gap size
      height: auto;
      margin: 0;
      padding: 0;
      overflow: hidden;
      line-height: 2.4rem;
      // default, allows one line
      white-space: nowrap;

      > abbr {
        text-decoration: none;
      }
    }
  }

  &.collapsedMultiDay {
    padding: 2px 0 0 8px;
    .unitHeadingAccent {
      padding: 4px 0;
    }
    abbr {
      white-space: nowrap;
    }
  }

  &.compressed {
    padding: 2px 0 0 4px;
  }

  // size tier: 0.5 (default minimum)
  &[data-size-tier='0.5'] {
    .unitHeadingAccent {
      padding: 4px 0;
    }
  }

  // size tier: 1.5
  &[data-size-tier='1.5'] {
    .unitHeadingWrapper {
      h3.unitHeading {
        // allows two lines
        @include lineClamp(2);
        white-space: normal;
      }
    }
    .unitHeadingAccent {
      padding: 4px 0;
    }
  }

  // size tier: 2
  &[data-size-tier='2'] {
    .unitHeadingWrapper {
      h3.unitHeading {
        // allows two lines
        @include lineClamp(2);
        white-space: normal;
      }
    }

    .displayLocation {
      // allows three lines
      @include lineClamp(3);
      white-space: normal;
    }
    .unitHeadingAccent {
      padding: 4px 0;
    }
  }

  // size tier: 3
  &[data-size-tier='3'] {
    .unitHeadingWrapper {
      h3.unitHeading {
        // allows two lines
        @include lineClamp(2);
        white-space: normal;
      }
    }

    .displayLocation {
      // allows four lines
      @include lineClamp(4);
      white-space: normal;
    }
    .unitHeadingAccent {
      padding: 4px 0;
    }
  }

  .unitHeadingWrapperCompressed {
    .unitHeadingAccent {
      margin-right: 4px;
    }

    .unitHeading {
      white-space: pre;

      > abbr {
        text-decoration: none;
      }
    }

    .classType {
      font-size: 1.6rem;
    }
  }
}
