@import 'src/styles/imports';

.editLink {
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  border-radius: var(--card-border-radius);
  transition: background-color 0.3s;
  max-width: calc(100vw - 32px);
  width: 40rem;

  @media screen and (max-width: $mobile-layout-width) {
    height: 100%;
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .link {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    margin: 0;
    height: 30rem;
    gap: 1.6rem;
    overflow-y: scroll;
    @include customScrollbar;

    @media screen and (max-width: $mobile-layout-width) {
      height: 100%;
      padding: 2rem;
    }
    .item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .header {
      display: grid;
      grid-template-columns: 4rem 4.6rem 1fr 1fr 4rem;
      gap: 1.6rem;

      h4 {
        color: var(--card-text-secondary-color);
        padding-left: 0.5rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: unset;
        margin: 0;
        white-space: noWrap;
        overflow: hidden;
        text-overflow: clip;
      }
      .icon {
        grid-column: 2 / span 1;
      }
      .name {
        grid-column: 3 / span 1;
      }
      .url {
        grid-column: 4 / span 1;
      }
    }
  }

  .actions {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    gap: 1rem;
    padding: 2rem;

    @media screen and (max-width: $mobile-layout-width) {
      padding: 1rem 2rem;
    }

    > div {
      display: flex;
      gap: 2rem;
    }
  }
}
