@import 'src/styles/imports';

.header {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  color: var(--canvas-text-color);
  // offsetting top space for scroll into view
  scroll-margin-top: $page-container-top-padding;

  .actions {
    display: flex;
    align-items: center;
    height: 100%;

    .button {
      white-space: nowrap;
    }

    .savingIndicator {
      display: flex;
      align-items: center;
      opacity: 0;

      &[data-status='completed'] {
        animation: fadeInOut 1.5s ease-in-out forwards;
      }
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
