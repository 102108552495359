@import 'src/styles/_variables.scss';

.classContainer {
  background: transparent;
  padding: 0;
  border-radius: 14px;
  background-repeat: no-repeat;
  text-align: left;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  transition-property: background-position, background-size, background-image,
    background-color;

  &:focus-visible {
    border-radius: 8px;
    outline: 2px solid var(--canvas-input-focused-border-color);
    outline-offset: 2px;
  }

  @media screen and (max-width: $mobile-layout-width) {
    border-radius: 10px;
  }
}

.Card {
  display: block;
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
  height: 100%;
  text-align: left;
  border: 0;
  opacity: 0;
  transform: translate(0, 40px);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);

  &.unroundedLeft {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &.unroundedRight {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &[data-active] {
    opacity: 1;
    transform: none;
  }

  &:hover {
    cursor: pointer;
  }
}
