.iconInput {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: content-box;
  border: 1px solid var(--card-border-color);
  background-color: var(--card-bg-color);
  overflow: hidden;
}

.button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  text-align: center;
  line-height: 0;
  cursor: pointer;

  &:hover {
    background: var(--card-active-bg-color);
  }
}
