.themesGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .themes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
