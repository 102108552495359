@import 'src/styles/imports';

.header {
  width: 100%;
  padding: 1.2rem 2rem 0.6rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    gap: 1rem;
    align-items: center;
    overflow: hidden;

    svg {
      flex-shrink: 0;
    }

    > h2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
  }
}
