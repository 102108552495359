.searchBar {
  transition: all 0.3s ease-in-out;
  & > div {
    max-width: none;
  }
  .searchBox {
    width: 100%;
    height: 42px;
    background-color: var(--card-bg-color);
  }
}
