@import 'src/styles/imports';

$custom-scrollbar-offset: 8px;

.pageWrapper {
  height: 100%;

  @media screen and (max-width: $mobile-layout-width) {
    height: 80vh;

    &[data-expanded='false'] {
      min-height: 70vh;
      height: auto;
    }

    &[data-banner-shown='SCHEDULE'] {
      height: auto;
      & [data-expanded='true'] {
        .container {
          height: 80vh;
        }
      }
      & [data-expanded='false'] {
        .container {
          min-height: 50vh;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: $layer-schedule-container;
}

.schedule {
  min-height: 100vh;
}

.header {
  color: var(--canvas-text-color);
  position: relative;
  z-index: $layer-cal-sticky-headers;
}

.navigation {
  align-items: center;
  color: var(--canvas-text-color);
  display: flex;
  justify-content: space-between;
  // maintain consistent height
  height: 40px;
  overflow: visible;
  margin-bottom: 0.4rem;

  .headerWeeks {
    display: flex;
    align-items: center;
    flex: 1;

    @media screen and (max-width: $mobile-layout-width) {
      gap: 0.4rem;
    }
  }

  .title {
    font-size: clamp(1.6rem, 2vw, 2.4rem);
    font-weight: 800;
    width: 100%;
    background: none;
    border: 0;
    cursor: pointer;
    color: currentColor;
    padding: 0;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      gap: 0.8rem;

      @media screen and (max-width: $mobile-layout-width) {
        gap: 0.4rem;
      }

      .viewMiniCalendar {
        transition: transform 0.3s ease;
        height: 40px;
        padding: 8px;
        border-radius: 50%;

        @media (hover) {
          &:hover {
            background: var(--canvas-active-bg-color);
          }
        }

        svg {
          pointer-events: none;
        }
      }
    }
  }

  .iconButton {
    &:focus-visible {
      border-radius: 44px;
      outline-offset: 2px;
    }
  }
}

.headerControls {
  align-items: center;
  display: flex;
  gap: 1.6rem;

  @media screen and (max-width: $mobile-layout-width) {
    gap: 0.4rem;
  }
}

.slider {
  margin: 32px 0 24px;
  height: 25px;
  opacity: 1;
  transition: height 0.3s ease-in-out, opacity 0.25s linear,
    margin 0.3s ease-in-out;
  position: relative;
  z-index: $layer-schedule-slider;
}

.warningBox {
  margin-bottom: 2rem;
}
