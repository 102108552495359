@import 'src/styles/imports';

.settingsButton {
  width: 40px;
  height: 40px;
  justify-content: center;

  &:focus-visible {
    border-radius: 44px;
    outline-offset: 2px;
  }
}

.closeButton {
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--card-text-color);

  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;

  &:focus-visible {
    border-radius: 44px;
    outline: 2px solid var(--card-input-focused-border-color);
    outline-offset: 2px;
  }
}

.mobileSettingsContainer {
  display: grid;
  gap: 24px;

  h2 {
    padding-bottom: 8px;
  }
}

.desktopSettingsContainer {
  position: absolute;
  right: 0;
  display: block;
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  border-radius: 16px;
  width: 300px;
  padding-bottom: 12px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.75);
  backdrop-filter: var(--card-backdrop-filter);

  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 12px;

    .heading {
      &:focus-visible {
        outline: 0;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include customScrollbar;

    .sidePadded {
      padding-left: 20px;
      padding-right: 20px;
    }

    .subheading {
      margin-bottom: 8px;
    }
  }
}

.unitsList {
  // remove default list styling
  list-style-type: none;
  padding: 0;
  margin: 0;

  .unitRow {
    border-radius: 8px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

button.backButton {
  width: auto; // override default button width styling from modal
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: bolder;
  padding: 0;
}

.loading {
  display: flex;
  justify-content: center;
}
