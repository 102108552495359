.assessmentsWarning {
  display: flex;
  gap: 4px;
  justify-content: center;
  color: var(--canvas-text-color);
  opacity: 0.6;
  padding: 0 2rem;
  p {
    font-size: 14px;
    svg {
      top: 4px;
    }
  }
}
