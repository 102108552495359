@import '../../../../styles/mixins';
@import '../../../../styles/variables';

@mixin dynamicHeader {
  font-size: clamp(3.2rem, 4vw, 4.8rem);
  line-height: clamp(3.8rem, 4.8vw, 5.6rem);
  font-weight: 800;
}

.upcomingEventDetail {
  padding-top: 140px;
  height: 100%;
  color: var(--canvas-text-color);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.6rem;
  order: -1;

  @media screen and (max-width: $mobile-layout-width) {
    display: none;
  }
}

.noEventsToday {
  @include gappedColumn(12px);
  justify-content: center;
  color: var(--canvas-text-color);

  .header {
    @include dynamicHeader();
  }
}

.classHeader {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  margin-bottom: 1rem;

  .header {
    @include dynamicHeader();
    letter-spacing: -0.02em;
    margin-bottom: 0.4rem;
  }
}

.largeTime {
  font-size: clamp(2rem, 2vw, 2.4rem);
  font-weight: 600;
}
.detailSection {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  svg {
    stroke: var(--canvas-text-color) !important;
  }
  h3 {
    color: var(--canvas-text-color);
  }
}

.workshopWrapper {
  @include gappedColumn(30px);
  padding-bottom: 2rem;
}

.moodleButton {
  align-self: start;
}

.actions {
  margin: 40px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 6rem;

  &:first-child {
    background-color: var(--card-bg-color);
    color: var(--canvas-bg-color);
    svg {
      stroke: var(--canvas-bg-color);
    }
  }
}

.disclaimer {
  display: flex;
  align-items: flex-start;
  line-height: 16px;
  gap: 0.4rem;
  opacity: 60%;
  font-size: 14px;
}
