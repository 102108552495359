.menuList {
  // remove default list styling
  list-style-type: none;
  padding: 0;
  margin: 0;

  // actual styling
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 1px 1rem;
}

.menuListItem {
  // remove default button styling
  border: none;
  background: none;

  // actual styling
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  color: var(--card-text-color);

  &.subItem {
    padding-left: 4rem;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--card-input-focused-bg-color);
  }
}

.submenuAccordion {
  :global(.subHeader) {
    padding-right: 1.8rem;
    border-radius: 12px;

    &:hover {
      cursor: pointer;
      background-color: var(--card-input-focused-bg-color);
    }
  }
}
