@import 'src/styles/imports';

.widgetLibrary {
  z-index: $layer-widget-library;

  button {
    appearance: none;
    background: none;
    border: none;

    &:focus-visible {
      border-radius: 40px;
    }
  }
  .widgetListContainer {
    padding: 0.4rem 2rem 2rem;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include customScrollbar;

    // mobile
    @media screen and (max-width: $mobile-layout-width) {
      align-items: flex-start;
      max-height: 100%;
      box-shadow: none;
      border-radius: 0;
      margin: 0px 0px 4rem 0px;
    }
  }
  .widgetSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .widgetList {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem; /* Space between items */
    transition: background-color 0.3s;
    background-color: var(--card-bg-color);
    color: var(--card-text-color);
    border-radius: var(--card-border-radius);
    backdrop-filter: var(--card-backdrop-filter);

    .widgetListItem {
      width: 100%;
      height: 100%;
      list-style: none;
      line-height: 150%;
      font-size: var(--global-font-size);
      color: var(--card-text-color);
      border: 1px solid var(--card-border-color);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0;
      overflow: hidden;
      transition: box-shadow 0.3s ease, transform 0.3s ease;

      .widgetPreview {
        width: 100%;
        height: 15rem;
        padding-top: 2rem;
        display: flex;
        justify-content: center;

        img {
          height: 100%;
        }
      }

      .widgetName {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        padding: 2rem;
        width: 100%;
        .tagGroup {
          display: flex;
          gap: 0.4rem;
          padding-top: 0.4rem;
          width: fit-content;
        }
      }
      // mobile
      @media screen and (max-width: $mobile-layout-width) {
        display: flex;
        flex-direction: row;
        .widgetPreview {
          width: 20rem;
          height: 100%;
          justify-content: left;
          overflow: hidden;
          align-items: flex-end;
          img {
            height: 15rem;
          }
        }
        .widgetName {
          width: 100%;
        }
      }

      &:hover {
        cursor: pointer;
        transition: box-shadow 0.3s ease, transform 0.3s ease;
        box-shadow: $shadow-lg;
        transform: translateY(-0.4rem); /* Moves the div up by 4px */
      }

      &:focus-visible {
        border-radius: 12px;
        outline-offset: -2px;
      }
      svg {
        stroke: currentColor;
      }
    }
  }
}

.entryBtn {
  white-space: nowrap;
  padding: 4px 20px;
}
