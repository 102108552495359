.errorMessage {
  position: absolute;
  border-radius: 4px;
  width: max-content;
  align-items: center;
  top: -4rem;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: var(--card-bg-color);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-intent-attention);

  svg {
    stroke: currentColor;
  }

  .poke {
    width: 8px;
    height: 8px;
    background-color: var(--card-bg-color);
    position: absolute;
    left: 1rem;
    bottom: -4px;
    transform: rotate(45deg);
  }
}
