@import 'src/styles/imports';

.mPass {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--card-border-radius);
  overflow: hidden;

  > img {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem 2rem;

    .accountBalance {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .amount {
        line-height: 4rem;
        font-size: 4rem;
        font-weight: 900;
      }
    }

    .additionalDetails {
      width: 100%;
      display: flex;

      .cardNumber,
      .printingBalance {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .printingBalance {
        text-align: end;
      }
    }

    .balance {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .externalLink {
    color: var(--card-text-color);
  }
}
