@import 'src/styles/imports';

.linkTile {
  width: 100%;
  height: 100%;

  .link {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    color: var(--card-text-primary-color);
    padding: 0 1rem 1.6rem;
    overflow: hidden;

    .icon {
      height: 3.2rem;
      width: 3.2rem;
      font-size: 3.2rem;
      line-height: 3.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .linkName {
      font-weight: 500;
    }
  }
  .emptyLink {
    padding: 2rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include gappedColumn(1rem);

    .graphic {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include gappedColumn(1rem);

      img {
        width: 40%;
        max-width: 100px;
      }
    }
  }

  .editLink {
    width: 100%;
    padding: 0.8rem 0;
    display: flex;
    justify-content: center;
  }
}
