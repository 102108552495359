@import 'src/styles/imports';

.results {
  height: 100%;
  border-radius: var(--card-border-radius);
  overflow: hidden;

  > img {
    display: block;
    position: absolute;
    height: 154px;
    width: 111px;
    right: 0;
    bottom: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    justify-content: space-between;
    gap: 16px;

    .section {
      @include gappedColumn(4px);
    }

    .gpaAndWam {
      @include gappedColumn(16px);
    }

    .externalLink {
      color: var(--card-text-color);
    }

    [data-unavailable-wam='true'] {
      display: flex;
      align-items: center;
    }
  }

  .centreContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
