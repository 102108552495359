@use 'sass:math';
@import 'src/styles/imports';

$dot-size: 8px;
$half-dot-size: math.div($dot-size, 2);
$bar-thickness: 2px;

.timeIndicator {
  position: absolute;
  left: 0;
  width: 100%;
  height: $bar-thickness;
  background: var(--color-intent-attention);
  /* Needs to be higher than number of possible events per column on schedule */
  z-index: $layer-time-indicator;

  &::before {
    content: '';
    position: absolute;
    left: $half-dot-size * -1;
    top: math.div(($dot-size - $bar-thickness), 2) * -1;
    width: $dot-size;
    height: $dot-size;
    background: var(--color-intent-attention);
    border-radius: 50%;
  }
}
