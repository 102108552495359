@import 'src/styles/imports';
div.relativePanelCard {
  display: flex;
  padding: 10px 0;
}

.newsCard {
  display: flex;
  flex-direction: column;
  height: 100%;

  z-index: 100;

  .desktopCardPadding {
    padding: 10px 20px;
  }

  .mobileCardPadding {
    padding: 10px 0;
  }

  .closeButton {
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    appearance: none;
    background: none;
    border: none;
    width: 40px;
    height: 40px;
    margin: -8px -8px 0 0;
    padding: 0;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include customScrollbar;
  }
}

@media screen and (max-width: $mobile-layout-width) {
  .newsCard {
    width: 100%;
  }
}

.newsHeader {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
