@import '../../../styles/mixins';

.titleWrapper {
  display: flex;
  gap: 5px;
  font-weight: normal;

  &.canvas {
    color: var(--canvas-text-color);
  }

  &.card {
    color: var(--card-text-color);
  }
}

.number {
  width: 21px;
  height: 21px;
  font-size: 14px;
  border-radius: 50%;
  line-height: 1.4;

  &.canvas {
    color: var(--canvas-text-color);
    border: 1px solid var(--canvas-text-color);

    &.active {
      background: var(--canvas-text-color);
      color: var(--canvas-bg-color);
    }
  }

  &.card {
    color: var(--card-text-color);
    border: 1px solid var(--card-border-color);

    &.active {
      background: var(--card-text-color);
      color: var(--card-bg-color);
      border-color: var(--card-text-color);
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  & div {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: fit-content;
  }
}

.customAccordion {
  :global(.accordionItems) {
    @include gappedColumn(10px);
  }

  :global(.childrenContent) {
    padding: 20px 25px;
  }

  &.canvas {
    :global(.subHeader) {
      svg {
        stroke: var(--canvas-text-color);
      }
    }
  }

  &.card {
    :global(.subHeader) {
      svg {
        stroke: var(--card-text-color);
      }
    }
  }
}
