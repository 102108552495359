@import 'src/styles/imports';

.newsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 0 0 1.2rem;
  gap: 3rem;

  // because we are catering for a scrollbar above
  @supports not (scrollbar-width: none) {
    // handle no scroll bar, by adding equivalent padding
    padding-right: 0.4rem;
  }

  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .newsItem {
    width: 100%;

    .clickable {
      appearance: none;
      border: none;
      background: none;
      text-align: start;
      padding: 0.8rem;
      width: 100%;
      border-radius: 0.8rem;
      transition: background-color 0.3s ease;
      * {
        pointer-events: none;
      }
      &:hover {
        cursor: pointer;
        background-color: var(--card-input-focused-bg-color);
      }

      &:focus-visible {
        outline-offset: 2px;
        border-radius: 8px;
      }
    }

    .links {
      margin: 0.8rem 0 0 0.8rem;
      a:focus-visible {
        border-radius: 8px;
      }
    }

    &:last-child {
      padding-bottom: 1.5rem;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .channelIcon {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-radius: 100px;
  }
  .channelName {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    line-height: 20px;

    .time {
      color: var(--card-text-secondary-color);
    }
  }
}

.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--card-text-color);

  .description {
    @extend .lineClamp;
    &.largeText {
      -webkit-line-clamp: 3;
    }
    &.smallText {
      -webkit-line-clamp: 2;
    }
  }

  .title {
    @extend .lineClamp;
    &.largeText {
      -webkit-line-clamp: 2;
    }
    &.smallText {
      -webkit-line-clamp: 1;
    }
  }
}

.description {
  line-height: 150%;
  font-size: 1.6rem;
}

.image {
  width: 100%;
  border-radius: 8px;
  height: 200px;
  object-fit: cover;
}

.large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  img {
    width: 320px;
  }
}
