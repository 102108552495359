@import 'src/styles/imports';
.menu {
  background-color: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  border: var(--card-border-style) var(--card-border-width)
    var(--card-border-color);
  box-shadow: $shadow-xl;
  backdrop-filter: var(--card-backdrop-filter);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.6rem;
  .label {
    color: var(--card-text-secondary-color);
  }
}
