// Font system

// Typographic levels

// Display
.display {
  font-size: clamp(3.2rem, 3vw, 4.8rem);
  font-weight: 700;
  letter-spacing: -0.025rem;
  line-height: 120%;
  margin: 0;
}

// Headings
.heading {
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -0.025rem;
  line-height: 120%;
  margin: 0;
}

//body
.body {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}

// label
.label {
  font-size: 1.4rem;
  line-height: 1.6rem;
  line-height: 140%;
  margin: 0;
}

// Font size

.xxl {
  font-size: 6.4rem;
}

.xl {
  font-size: 4rem;
}
.lg {
  font-size: 3.2rem;
}
.md {
  font-size: clamp(2rem, 2vw, 2.4rem);
}
.sm {
  font-size: 1.6rem;
}

// Font weight
.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

// Status
.success {
  color: var(--color-intent-success);
}

.warning {
  color: var(--color-intent-warning);
}
.attention {
  color: var(--color-intent-attention);
}

//Styles
.underline {
  text-decoration: underline;
}
