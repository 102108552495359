@import '~@monash/portal-react/dist/styles/variables.scss';

$nav-container-height: 80px;
$page-container-top-padding: 140px;
$mobile-page-container-top-padding: 80px;
$tablet-layout-width: 1200px;
$mobile-layout-width: 699px;
$small-mobile-layout-width: 460px;

// z-index layers
$layer-time-indicator: 20;
$layer-widget-library: 95;
$layer-widget-preview: 96;
$layer-schedule-container: 96;
$layer-schedule-slider: 96;
$layer-cal-sticky-headers: 97;
$layer-schedule-header: 97;
$layer-notification: 97;
$layer-nav: 98;
$layer-sticky-container: 99;
$layer-draggable-window: 99;
$layer-search-modal: 100;
$layer-modal-mobile: 100;
