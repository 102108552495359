@import 'src/styles/imports';

.tab {
  display: inline-block;
  border: none;
  background: transparent;
  padding: 4px 4px;
  margin: 0 10px;
  cursor: pointer;
  color: var(--canvas-text-color);
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 24ch;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--canvas-active-bg-color);
  }
}
